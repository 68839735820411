import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GiphyFetch} from '@giphy/js-fetch-api';

const mdr = require('markdown-it')();

const gf = new GiphyFetch('0l2pmrZ1wH3ODm4qoi8vAC4Bz5NeYJev');

@Component({
  selector: 'app-mdeditor',
  templateUrl: './mdeditor.component.html',
  styleUrls: ['./mdeditor.component.scss']
})
export class MdeditorComponent implements OnInit {
  @Input() id: string;
  @Input() existingText = '';
  @Output() textChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  handleClick(type: string): void {
    let prefix: string;
    let suffix: string;

    switch (type) {
      case 'b':
        prefix = '**';
        suffix = '**';
        break;
      case 'i':
        prefix = '*';
        suffix = '*';
        break;
      case 'u':
        prefix = '<ins>';
        suffix = '</ins>';
        break;
      case 's':
        prefix = '~~';
        suffix = '~~';
        break;
      case 'h1':
        prefix = '# ';
        suffix = '';
        break;
      case 'h2':
        prefix = '## ';
        suffix = '';
        break;
      case 'h3':
        prefix = '### ';
        suffix = '';
        break;
      case 'a':
        prefix = '[';
        suffix = '](url)';
        break;
      case 'img':
        prefix = '![';
        suffix = '](url)';
        break;
    }

    const textarea = document.getElementById(`${this.id}-input`) as HTMLInputElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    let text = textarea.value;
    text = `${text.substring(0, start)}${prefix}${text.substring(start, end)}${suffix}${text.substring(end)}`;
    textarea.value = text;
    textarea.selectionStart = start + prefix.length;
    textarea.selectionEnd = start + text.substring(start, end + suffix.length).length;
    textarea.focus();
  }

  preview(): void {
    const preview = document.getElementById(`${this.id}-preview`);
    const input = document.getElementById(`${this.id}-input`) as HTMLInputElement;

    preview.innerHTML = mdr.render(input.value);

  }

  async search(): Promise<void> {
    const search = (document.getElementById(`${this.id}-giphySearch`) as HTMLInputElement).value;
    const {data: gifs} = await gf.search(search, {limit: 9, sort: 'relevant', lang: 'en', type: 'gifs'});
    const display = document.getElementById(`${this.id}-giphyResults`);
    const instruction = document.getElementById(`${this.id}-instruction`);

    for (let i = 0; i < gifs.length; i++) {
      const img = document.getElementById(`${this.id}-giphy${i}`);
      img.setAttribute('src', gifs[i].images.preview_gif.url);
      img.style.cursor = 'pointer';

      img.onclick = () => {
        navigator.clipboard.writeText(`![${search}](${gifs[i].images.original.url})`);
        display.classList.add('d-none');
        instruction.innerHTML = 'gif copied...';
        setTimeout(() => {
          // Click back to feedback edit page
          document.getElementById(`${this.id}-edit-link`).click();

          // Get the text box and focus it
          const textarea = document.getElementById(`${this.id}-input`) as HTMLInputElement;
          textarea.focus();

          // Paste the copied gif from the clipboard
          navigator.clipboard.readText().then(text => {
            textarea.value = textarea.value + text;
            this.textChanged.emit({text: textarea.value});
          });
        }, 1000);
      };
    }

    display.classList.remove('d-none');
    instruction.innerHTML = 'Click on a gif to copy it';
  }

  textAreaChanged($event: KeyboardEvent): void {
    const textArea = $event.target as HTMLTextAreaElement;
    this.textChanged.emit({text: textArea.value});
  }
}
