<div class="accordion">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" (click)="loadDescription()" data-bs-toggle="collapse" [attr.data-bs-target]="'#problem-description-' + problemId" aria-expanded="false">
        Problem Description
      </button>
    </h2>
    <div [id]="'problem-description-' + problemId" class="accordion-collapse collapse overflow-scroll" aria-labelledby="headingOne">
      <div class="accordion-body">
        <div class="accordion accordion-flush">
          <div class="accordion-item problem-description">
            <div *ngIf="!pathString" class="loading-container">
              <div class="spinner-border"></div>
            </div>
            <div class="code-view-height" *ngIf="pathString" NgxMd [data]="pathString"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
