import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export class Submission {
  submission: File
  questionNumber: number

  constructor(q: number, file: File) {
    this.questionNumber= q;
    this.submission = file;
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class TempFileStoreModule { 
  static submsissions: Array<Submission> = new Array<Submission>();

  static appendSubmission(questionNumber: number, file: File): void {
    this.submsissions.push(new Submission(questionNumber, file))
  }

  static getSubmissionByNumber(questionNumber: number): Submission {
    return this.submsissions.find(x => x.questionNumber == questionNumber);
  }

  static getAllSubmissions(): Array<Submission> {
    return this.submsissions;
  }

}
