import { ServerService } from '../services/server.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../services/auth.service';
import {AnnouncementService} from "../services/announcement.service";
import {Subscription} from "rxjs";
import {Announcement} from "../models/responseObjects";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  showNavigation = false;
  userType: string;
  unreadAnnouncements = 0;
  totalAnnouncements = 0;
  announcementSubscription: Subscription;

  constructor(public router: Router, activatedRoute: ActivatedRoute, public authService: AuthService,
              private server: ServerService, private announcements: AnnouncementService) {
  }

  active(route: string): string {
    return route === this.router.url ? 'active' : '';
  }

  ngOnInit(): void {

    this.authService.isLoggedIn.subscribe(status => {
      this.showNavigation = status;
      if (!status) { this.userType = undefined; }
    });
    this.authService.isAdminLoggedIn.subscribe(status => {
      if (status) { this.userType = 'admin'; }
    });
    this.authService.isJudgeLoggedIn.subscribe(status => {
      if (status) { this.userType = 'judge'; }
    });
    this.authService.isTeamLoggedIn.subscribe(status => {
      if (status) {
        this.userType = 'team';
      }
    });

    console.log(this.userType);
    this.startObservingAnnouncements();
  }

  /**
   * Initializes the observable for announcements
   */
  startObservingAnnouncements(): void {
    const subscribeCallback = (response: Announcement[]) => {
      this.totalAnnouncements = response.length;
      this.unreadAnnouncements = this.totalAnnouncements -
        JSON.parse(localStorage.getItem('readAnnouncements'));
    }
    this.announcementSubscription = this.announcements.pollObservable
      .subscribe(response => subscribeCallback(response));
  }

  updateReadAnnouncements(): void {
    localStorage.setItem('readAnnouncements', JSON.stringify(this.totalAnnouncements));
    this.unreadAnnouncements = 0;
  }

  ngOnDestroy(): void {
    this.announcementSubscription.unsubscribe();
  }
}

