<section class="hero is-light is-fullheight is-bold">
    <div class="container justify-content-center mt-5 mb-5">
      <div>
        <!--<div class="alert alert-{{ feedbackType }} alert-dismissible " *ngIf="feedbackProblem === -1">
          {{ feedbackMessage }}
          <button type="button" class="btn-close" (click)="clearFeedback()"></button>
         </div> -->
        <div class="card">
          <div class="card-body">
            <h2 class="display-3">Announcements</h2>
          </div>
        </div>
        <br>
        <div *ngFor="let announcement of announcements; let i = index">
            <div class="alert alert-{{ announcements[announcements.length - (i + 1)].messageType }}" >
              <h5>{{ announcements[announcements.length - (i + 1)].message }}</h5>
              <hr>
              <p class="mb-0">{{ announcements[announcements.length - (i + 1)].time | date:'shortTime' }}</p>
            </div>
        </div>
      </div>
    </div>
  </section>
