<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button [id]="id + '-edit-link'" class="nav-link active" data-bs-toggle="tab" [attr.data-bs-target]="'#' + this.id + '-edit'"><i class="fas fa-edit"></i> Edit</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" data-bs-toggle="tab" [attr.data-bs-target]="'#' + this.id + '-giphy'"><i class="fas fa-file-video"></i> Giphy</button>
  </li>
  <li class="nav-item" role="presentation">
    <button (click)="preview()" class="nav-link" data-bs-toggle="tab" [attr.data-bs-target]="'#' + this.id + '-preview'">Preview</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active p-0" [id]="this.id + '-edit'">
    <div class="card card-primary">
      <div class="card-header d-flex">
        <div class="btn-group btn-group-sm mr-2">
          <button (click)="handleClick('h1')" class="btn btn-outline-secondary">H1</button>
          <button (click)="handleClick('h2')" class="btn btn-outline-secondary">H2</button>
          <button (click)="handleClick('h3')" class="btn btn-outline-secondary">H3</button>
        </div>
        &nbsp;
        <div class="btn-group btn-group-sm mr-2">
          <button (click)="handleClick('b')" class="btn btn-outline-secondary"><b>B</b></button>
          <button (click)="handleClick('i')" class="btn btn-outline-secondary"><em>I</em></button>
          <!-- <button (click)="handleClick('u')" class="btn btn-outline-secondary"><u>U</u></button>  -->
          <button (click)="handleClick('s')" class="btn btn-outline-secondary"><s>S</s></button>
        </div>
        &nbsp;
        <div class="btn-group btn-group-sm">
          <button (click)="handleClick('a')" class="btn btn-outline-secondary"><i class="fas fa-link"></i></button>
        </div>
      </div>
      <textarea (keyup)="textAreaChanged($event)" [value]="existingText" name="input" [id]="id + '-input'" class="mdeditor-input form-control card-body p-1" rows=10 ></textarea>
    </div>
  </div>
  <div class="tab-pane fade p-2" [id]="id + '-preview'"></div>
  <div class="tab-pane fade p-2" [id]="id + '-giphy'">
    <div [id]="id + '-giphyResults'" class="d-none mb-3">
      <div class="row mb-1">
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy0'" class="w-100 h-100"></div>
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy1'" class="w-100 h-100"></div>
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy2'" class="w-100 h-100"></div>
      </div>
      <div class="row mb-1">
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy3'" class="w-100 h-100"></div>
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy4'" class="w-100 h-100"></div>
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy5'" class="w-100 h-100"></div>
      </div>
      <div class="row mb-1">
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy6'" class="w-100 h-100"></div>
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy7'" class="w-100 h-100"></div>
        <div class="col-sm-12 col-md-4"><img [id]="id + '-giphy8'" class="w-100 h-100"></div>
      </div>
    </div>
    <div class="text-center">
      <p [id]="id + '-instruction'">Search for a gif.</p>
    </div>
    <div class="input-group mb-0">
      <input [id]="id + '-giphySearch'" type="text" class="form-control" placeholder='"Good job!" "Awesome!"'>
      <button (click)="search()" class="btn btn-outline-success">Search</button>
    </div>
  </div>
</div>
