<div class="container-md">
  <div class="card">
    <div class="card-body">
      <h1 class="display-5">Submissions</h1>
      <ul class="nav nav-tabs" id="tabs" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="unscored-tab-item"
            data-bs-toggle="tab"
            data-bs-target="#unscored"
            type="button"
            role="tab"
            aria-controls="unscored"
            aria-selected="true"
          >
            Ready for Evaluation
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="scored-tab-item"
            data-bs-toggle="tab"
            data-bs-target="#scored"
            type="button"
            role="tab"
            aria-controls="scored"
            aria-selected="false"
          >
            Evaluated Submissions
          </button>
        </li>
      </ul>
      <div class="tab-content" id="tab-content">
        <div
          class="tab-pane fade show active"
          id="unscored"
          role="tabpanel"
          aria-labelledby="unscored-tab"
        >
          <app-submission-list preset="unscored" #unscored></app-submission-list>
        </div>
        <div
          class="tab-pane fade"
          id="scored"
          role="tabpanel"
          aria-labelledby="scored-tab"
        >
          <div class="row justify-content-center">
            <app-submission-list preset="scored" id="scored" #scored></app-submission-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
