import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-clock',
  template: '<div class=\"lead mb-1\">{{ compStatus }}</div>'
})

export class ClockComponent implements OnInit, OnDestroy {
  @Output() compStarted = new EventEmitter<any>();
  @Output() compEnded = new EventEmitter<any>();
  @Output() compNotYetStarted = new EventEmitter<any>();
  @Output() stateChanged = new EventEmitter<string>();

  compStatus: string;
  compStatusCode: number;

  clockInterval: any;
  currentTime: Date;
  startTime: Date;
  endTime: Date;

  constructor(private server: ServerService) {
  }

  async ngOnInit(): Promise<void> {
    await this.fetchTimes();  // Fetches Start/End Times from db
    await this.clock();             // Starts clock that shows the count down
  }

  async fetchTimes(): Promise<void> {
    return this.server.request('GET', '/api/contest/time').toPromise().then(response => {
      this.startTime = new Date(response.start);
      this.endTime = new Date(response.end);
      this.currentTime = new Date(response.serverTime);
    }).catch((error) => {
      console.log(error)
    });
  }

  async clock() {
    const updateTime = () => {
      this.updateStatus();
      this.fetchTimes();
    };
    updateTime();
    this.clockInterval = setInterval(updateTime, 1000);
  }

  async updateStatus() { // Called every second
    if (this.startTime == null || this.endTime == null) {
      this.compStatus = '';
      return;
    }

    const now = this.currentTime.getTime();
    const beg = this.startTime.getTime();
    const end = this.endTime.getTime();
    if (beg >= end) {
      this.compStatus = '';
      return;
    } else if (now >= end) {
      this.compStatus = 'The competition has ended!';
      if (this.compStatusCode !== 1) {
        this.compStatusCode = 1;
        this.compEnded.emit();
        this.stateChanged.emit("ended")
      }
      return;
    }

    let days: number;
    let hours: number;
    let minutes: number;
    let seconds: number;
    let diff: number;
    if (now < beg) { // Competition not started yet
      diff = beg - now;
      days = Math.floor(diff / (1000 * 60 * 60 * 24));
      hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((diff % (1000 * 60)) / 1000);

      this.compStatus = 'The competition will start in ';
      if (days > 0) { this.compStatus += days + 'd '; }
      if (hours > 0 || days > 0) { this.compStatus += hours + 'h '; }
      if (minutes > 0 || hours > 0 || days > 0) { this.compStatus += minutes + 'm '; }
      if (seconds >= 0 || minutes > 0 || hours > 0 || days > 0) { this.compStatus += seconds + 's '; }


      if (this.compStatusCode !== -1) {
        this.compStatusCode = -1;
        this.compNotYetStarted.emit();
        this.stateChanged.emit("waiting to start")
      }

    }  else if (now >= beg && now < end) { // Competition in progress
      diff = end - now;
      days = Math.floor(diff / (1000 * 60 * 60 * 24));
      hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((diff % (1000 * 60)) / 1000);

      this.compStatus = 'Competition in progress | Time left: ';
      if (days > 0) { this.compStatus += days + 'd '; }
      if (hours > 0 || days > 0) { this.compStatus += hours + 'h '; }
      if (minutes > 0 || hours > 0 || days > 0) { this.compStatus += minutes + 'm '; }
      if (seconds >= 0 || minutes > 0 || hours > 0 || days > 0) { this.compStatus += seconds + 's '; }

      if (this.compStatusCode !== 0) {
        this.compStatusCode = 0;
        this.compStarted.emit();
        this.stateChanged.emit("started")
      }
    }
  }

  // Stops interval when user leaves the problem page.
  async ngOnDestroy(): Promise<any> {
    clearInterval(this.clockInterval);
  }
}
