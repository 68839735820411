<div class="container">
  <div
    class="alert alert-{{ feedbackType }} alert-dismissible "
    *ngIf="feedbackProblem === -1"
  >
    {{ feedbackMessage }}
    <button type="button" class="btn-close" (click)="clearFeedback()"></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h1 class="display-5 mb-0">Register Judge</h1>
      <p class="lead">Please enter judge details</p>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col">
            <label for="name">Judge Name</label>
            <input
              type="text"
              class="input form-control"
              name="name"
              id="name"
              formControlName="name"
              placeholder="Enter judge name"
              required
            />
          </div>
          <div class="col">
            <label for="username">Username</label>
            <input
              type="username"
              class="input form-control"
              name="username"
              id="username"
              formControlName="username"
              placeholder="Enter username"
              required
            />
          </div>
          <div class="col-2">
            <br />
            <button type="submit" class="btn btn-primary form-control">
              Register
            </button>
          </div>
        </div>
      </form>
      <hr class="mb-0">
      <table class="table table-light table-striped caption-top">
        <caption>
          List of Registered Judges
        </caption>
        <thead>
          <tr>
            <th width="2" scope="col">Name</th>
            <th width="2" scope="col">Username</th>
            <th width="2" scope="col">Status</th>
            <th width="2" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let judge of judges">
            <td>{{ judge.name }}</td>
            <td>{{ judge.username }}</td>
            <td>
              <i
                class="fas fa-user-{{ judge.activated ? 'check' : 'times' }}"
              ></i>
              {{ judge.activated ? "Activated" : "Not Activated" }}
            </td>
            <td *ngIf="judge.activated && confirm != judge.id">
              <button
                type="button"
                class="btn btn-sm btn-warning"
                (click)="confirm = judge.id"
              >
                <i class="fa fa-trash"></i> Delete Password
              </button>
              &nbsp;
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="toggleAdmin(judge)"
                *ngIf="judge.type !== 'admin'"
              >
                <i class="fa fa-user-shield"></i> Make Admin
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="toggleAdmin(judge)"
                *ngIf="judge.type === 'admin'"
              >
                <i class="fa fa-user"></i> Make Judge
              </button>
            </td>
            <td *ngIf="judge.activated && confirm == judge.id">
              <button
                type="button"
                class="btn btn-sm btn-danger"
                (click)="deletePassword(judge.username)"
              >
                <i class="fa fa-trash"></i>&nbsp;<b>Confirm</b> Delete?
              </button>
            </td>
            <td *ngIf="!judge.activated"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
