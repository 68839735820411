
<div class = "page">
  <div class = "table table-hover">
    <table>
    <tr id = "header">
      <th>Problem</th>
      <th>Score</th>
      <th>Comments</th>
      <th></th>
    </tr>
    <tr id = "problem1">
      <td>
        1
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button1" (click)="button1()">SEE</button>
      </td>
    </tr>
    <tr id = "problem2">
      <td>
        2
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button2">SEE</button>
      </td>
    </tr>
    <tr id = "problem3">
      <td>
        3
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button3">SEE</button>
      </td>
    </tr>
    <tr id = "problem4">
      <td>
        4
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button4">SEE</button>
      </td>
    </tr>
    <tr id = "problem5">
      <td>
        5
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button5">SEE</button>
      </td>
    </tr>
    <tr id = "problem6">
      <td>
        6
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button6" onclick="button6()">SEE</button>
      </td>
    </tr>
    <tr id = "problem7">
      <td>
        7
      </td>
      <td>
        1
      </td>
      <td>
        <button id="button7">SEE</button>
      </td>
    </tr>
  </table>
</div>
<div class = "comment" *ngIf="showComment">
  <p>
    comment comment comment comment comment comment comment comment comment comment comment comment comment comment comment comment comment comment 
           comment2<br>
    comment2<br>
    comment2<br>
  </p>
  </div>
</div>
