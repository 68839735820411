<main class="form-newuser">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <svg class="symbol symbol_logo_full" width="100"><use xlink:href="/assets/img/icons.svg#logo_full"></use></svg>
    <h1 class="h3 mb-0 fw-normal">New User</h1>
    <p>Enter your username and choose a password.</p>
    <div class="form-floating mb-3">
      <input
        type="text" id="username" placeholder="Username"
        class="form-control {{ form.controls.Username.invalid && formInvalid ? 'is-invalid' : ''}}" formControlName="Username"
      >
      <label for="username">Username</label>
    </div>
    <div class="form-floating mb-3">
      <input
        type="password" id="password" placeholder="Password"
        class="form-control {{ form.controls.Password.invalid && formInvalid ? 'is-invalid' : ''}}" formControlName="Password"
      >
      <label for="password">Password</label>
    </div>
    <div class="form-floating mb-2">
      <input
        type="password" id="confirmPassword" placeholder="Confirm Password"
        class="form-control {{ form.controls.ConfirmPassword.invalid && formInvalid ? 'is-invalid' : ''}}" formControlName="ConfirmPassword"
      >
      <label for="confirmPpassword">Confirm Password</label>
      <div class="invalid-feedback">{{ message !== undefined ? message : '&nbsp;' }}</div>
    </div>
    <button class="btn btn-primary w-100 mb-2" type="submit">Set Password</button>
    OR
    <a routerLink="/" class="btn btn-primary w-100 mt-2">Back to Login</a>
  </form>
</main>
