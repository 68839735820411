import {Component, EventEmitter, Input, Output } from '@angular/core';
import {BootstrapTypes} from "../models/responseObjects";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})

export class ToastComponent {
  @Output() disposeEvent = new EventEmitter();

  @Input()
  title: string;

  @Input()
  message: string;

  @Input()
  type: BootstrapTypes;

  //Just makes sure mouse hovering won't hide the notification
  autoHide = true;

  dispose() {
    this.disposeEvent.emit();
  }
}
