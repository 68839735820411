import {Component, OnInit} from '@angular/core';
import {ServerService} from '../services/server.service';
import {TempFileStoreModule} from '../services/temp-file-store.module';

/**
 * This team view component will allow you to submit files to the database
 */
@Component({
  selector: 'app-team-view',
  templateUrl: './team-view.component.html',
  styleUrls: ['./team-view.component.scss']
})

export class TeamViewComponent implements OnInit {
  selectedFile: File;
  selectedQuestion = 0;
  validQuestions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  text: string;

  constructor(private server: ServerService) {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList): void {
    this.selectedFile = files[0];

    if (this.selectedQuestion !== 0) {
      (document.getElementById('submitButton') as HTMLInputElement).disabled = false;
    }
  }

  handleQuestionSelect(btn): void {
    this.selectedQuestion = btn.value;

    if (this.selectedFile) {
      (document.getElementById('submitButton') as HTMLInputElement).disabled = false;
    }
  }

  async onSubmit(): Promise<void> {
    this.text = await this.selectedFile.text();
    const lang = this.selectedFile.name.split('.').reverse()[0];

    TempFileStoreModule.appendSubmission(this.selectedQuestion, this.selectedFile);

    this.server.request('POST', this.server.routeSubmissionPost, {code: this.text, lang, problemId: this.selectedQuestion})
      .subscribe((e: any) => {
        console.log(e);
      });
    return;
  }

}
