<ngb-toast
  [header]="title"
  [autohide]="autoHide"
  (mouseenter)="autoHide = false"
  (mouseleave)="autoHide = true"
  [delay]="5000"
  class="bg-{{type}} mb-3"
  (hidden)="dispose()">
  {{message}}
</ngb-toast>
