import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ServerService} from '../services/server.service';
import Prism from 'prismjs';

@Component({
  selector: 'app-code-preview',
  templateUrl: './code-preview.component.html',
  styleUrls: ['./code-preview.component.scss']
})
export class CodePreviewComponent implements OnInit {
  @Input() submission: any;
  @ViewChild('codeView') codeView: ElementRef;

  constructor(public server: ServerService) {
  }


  ngOnInit(): void {
    this.server.request('GET', this.server.routeSubmissionGetSpecific + this.submission.id + '?withCode=true')
      .subscribe((result: any) => {
        if(Prism.languages.hasOwnProperty(result.lang)) {
          const grammar = Prism.languages[result.lang];
          this.codeView.nativeElement.innerHTML = Prism.highlight(result.code, grammar, result.lang);
        } else{
          this.codeView.nativeElement.innerText = result.code;
        }
      });
  }
}
