import {Component, Input} from '@angular/core';
import {ServerService} from "../services/server.service";
import {AuthService} from "../services/auth.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-delete-database',
  templateUrl: './delete-database.component.html',
  styleUrls: ['./delete-database.component.scss']
})
export class DeleteDatabaseComponent {

  ableToDelete: boolean = false;
  deletingStatus: string = 'safe';

  constructor(private server: ServerService, private authService: AuthService, private modalService: NgbModal) {

  }

  inputChanged(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement
    this.ableToDelete = input.value == "DELETE";
  }

  resetDatabase() {
    this.server
      .request('PUT', this.server.routeResetPut, {})
      .subscribe(
        (_) => {
          this.authService.logout();
        },
        (_) => console.log('failure')
      );
  }

  startDeleteDatabase() {
    this.deletingStatus = "started";
  }

  openDeleteModal() {
    this.deletingStatus = 'deleting';
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then();
  }
}
