<div class="container">

  <div class="card">
    <div class="card-body">
      <h1 class="display-5">About</h1>
      <p class="mb-2"><a href="/">Return to Home</a></p>
      <p class="mb-2"><b>Maintainer:</b> Dr. Rob Hasker</p>
      <p class="mb-2"><b>Developers:</b></p>
      <table class="table table-bordered mt-0 mb-0">
        <tbody>
          <tr>
            <td>
              <div>SDL Team 1</div>
              <div>2020-2021</div>
            </td>
            <td>
              Stuart Enters,
              Noah Ernst,
              Jacob Huebner,
              Chanfais Moua,
              Sean Voves
            </td>
          </tr>
          <tr>
            <td>
              <div>SDL Team 2</div>
              <div>2021-2022</div>
            </td>
            <td>
              Matej Koncos,
              David Lonski,
              Kenneth McDonough,
              Alan Van Dyke,
              Riley Woytas
            </td>
          </tr>
          <tr>
            <td>
              <div>SDL Team 3</div>
              <div>2022-2023</div>
            </td>
            <td>
              Riley Jensen,
              Joshua Bultman,
              Alex Goebel,
              Atreyu Schilling
            </td>
          </tr>
          <tr>
            <td>
              <div>SDL Team 4</div>
              <div>2024-2025</div>
            </td>
            <td>
              Tyge Plater,
              Dakota Nelson,
              David McIlveen,
              Will Peterson
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
