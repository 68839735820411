import {Component, ElementRef, OnInit, QueryList, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ServerService } from '../services/server.service';
import {CSVService} from '../services/csv.service';

@Component({
  selector: 'app-manage-problems',
  templateUrl: './manage-problems.component.html',
  styleUrls: ['./manage-problems.component.scss']
})
export class ManageProblemsComponent implements OnInit {
  problems: Array<{id: number, order: number, fileLocation: string}> = new Array<{id: number; order: number, fileLocation: string}>();
  practiceProblems: Array<{id: number; order: number, fileLocation: string}> = new Array<{id: number; order: number, fileLocation: string}>();

  form: UntypedFormGroup;
  form1: UntypedFormGroup;

  confirmDelete = -1;
  editId = -1;

  selectedFiles: FileList;

  problemsLocked: boolean;

  @ViewChild('descriptionUpload') descriptionUpload: ElementRef;

  error = { show: false, form: -1, message: '' };
  practiceMarked: boolean;

  setError(show: boolean, form?: number, message?: string): void {
    if (show === false) {
      this.error.show = false;
      this.error.form = -1;
      this.error.message = '';
    } else {
      this.error.show = true;
      this.error.form = form;
      this.error.message = message;
    }
  }

  constructor(
    private server: ServerService,
    private csv: CSVService
  ) {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(),
      points: new UntypedFormControl(),
      markPractice: new UntypedFormControl()
    });
    this.form1 = new UntypedFormGroup({
      csv: new UntypedFormControl()
    });
  }

  ngOnInit(): void {
    this.server.get(this.server.routeTimeGet).subscribe(data => {
      const now = new Date();
      const start = new Date(data.start);
      this.problemsLocked = now >= start;
    });

    this.practiceProblems = new Array<{id: number; order: number, fileLocation: string}>();
    this.problems = new Array<{id: number; order: number, fileLocation: string}>();
    this.server.get(this.server.routeProblemGetAll).subscribe(data => {
      for (const problem of data) {
        if (problem.isPractice) {
          this.practiceProblems.push(problem);
        } else {
          this.problems.push(problem);
        }
      }
      this.problems.sort((p1, p2) => p1.order - p2.order);
      this.practiceProblems.sort((p1, p2) => p1.order - p2.order);
    });
  }

  async moveUp(index: number, problems: Array<{id: number; order: number}>): Promise<void> {
    this.setError(false);
    const current = problems[index];
    const prior = problems[index - 1];

    if (prior === undefined) {
      return;
    }

    await this.swap(current.id, prior.id, problems);

    this.ngOnInit();
  }

  async moveDown(index: number, problems: Array<{id: number; order: number}>): Promise<void> {
    this.setError(false);
    const current = problems[index];
    const next = problems[index + 1];

    if (next === undefined) {
      return;
    }

    await this.swap(current.id, next.id, problems);

    this.ngOnInit();
  }

  async swap(idOne: number, idTwo: number, problems: Array<{id: number; order: number}>): Promise<void> {
    this.setError(false);
    const one = problems.find(p => p.id === idOne);
    const two = problems.find(p => p.id === idTwo);

    await this.setOrder(one.id, -1); // to avoid table constraint issues
    await this.setOrder(two.id, one.order);
    await this.setOrder(one.id, two.order);
  }

  async setOrder(id: number, order: number): Promise<void> {
    return new Promise(r => {
      this.server.request('PUT', this.server.routeProblemPut, { id, order }).subscribe(data => r(data), error => {
        this.setError(true, -1, error.error.result);
      });
    });
  }

  async practiceCheckboxChecked($event) {
    this.practiceMarked = $event.target.checked;
    if (this.practiceMarked) {
      this.form.get('points').setValue(0);
    } else {
      this.form.get('points').setValue(undefined);
    }
  }

  handleFileInput(files: FileList): void {
    this.selectedFiles = files;
  }

  async onSubmit(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    this.setError(false);

    const title = this.form.get('title').value;
    const points = this.form.get('points').value;
    const practice = this.form.get('markPractice').value || false;
    const files = this.descriptionUpload.nativeElement.files;
    const response = await this.createProblem(title, points, files, practice);
    if (response.success === true) {
      this.form.get('title').setValue(undefined);
      this.form.get('points').setValue(undefined);
      this.form.get('markPractice').setValue(false);
      this.practiceMarked = false;
      this.descriptionUpload.nativeElement.value = '';
      document.getElementById('title').focus();
      this.ngOnInit();
    } else {
      this.setError(true, -1, response.result);
    }
  }

  async delete(id: number): Promise<void> {
    this.server.request('delete', this.server.routeProblemDelete, { id }).subscribe(_ => {}, (error: any) => {
      this.confirmDelete = -1;
      this.setError(true, -1, error.error.result.result);
      console.log(error);
    }, () => this.ngOnInit());
  }

  async onSubmitCSV(): Promise<void> {
    this.setError(false);
    if (this.selectedFiles === undefined) {
      this.setError(true, 2, 'Please select a file.');
      return;
    }
    if (this.selectedFiles.length !== 1) {
      this.setError(true, 2, `Please select only one csv file [${this.selectedFiles.length}]`);
      return;
    }
    if (!this.selectedFiles[0].name.endsWith('.csv')) {
      const parts = this.selectedFiles[0].name.split('.');
      const extension = parts[parts.length - 1];
      this.setError(true, 2, `Type must be csv [${extension}]`);
      return;
    }
    let parsedProblems: string[][];
    try {
      parsedProblems = this.csv.parseProblems(await this.selectedFiles[0].text());
    } catch (error) {
      // Blanket error catch - This only ever faces the admin, but we may want it cleaner
        this.setError(true, 2, `Upload failed - ${error.message}. See console for complete details.`);
        console.log(`CSV parse error:\n${JSON.stringify(error)}`);
        return;
    }


    let failedLines = 0;
    for (const problem of parsedProblems) {
      if (problem.length !== 3) {
        failedLines++;
        continue;
      }
      const title = problem[this.csv.problemNameIndex];
      const practice = problem[this.csv.problemPracticeIndex] === 'true'
      const points = practice ? 0 : Number(problem[this.csv.problemPointIndex]);

      if (isNaN(points) || points < 0) {
        failedLines++;
        continue;
      }

      const formData = new FormData();
      formData.append('title', title);
      formData.append('points', points.toString());
      formData.append('practice', practice.toString());
      await this.server.request('POST', '/api/problem', formData).toPromise();
    }
    if (failedLines > 0) {
      this.setError(true, 2, `CSV could not parse ${failedLines} lines\
          - only ${parsedProblems.length - failedLines} problems added`);
    }
    // Angularify this ideally
    this.form1.get('csv').setValue(undefined);
    document.getElementById('csv').focus();

    this.ngOnInit();
  }

  async createProblem(title: string, points: number, files: FileList, practice: boolean): Promise<any> {
    if (!title || (!practice && !points) || !files) {
      return { success: false, result: 'Please fill out all fields.' };
    }
    if (isNaN(points) || points < 0) {
      return { success: false, result: 'Points must be a valid natural number'};
    }
    if(files.length > 0) {
      let mdCount = 0;
      for (let i = 0; i < files.length; i++) {
        if (files[i].name.endsWith('.md') || files[i].name.endsWith('.markdown')) {
          mdCount++;
        }
      }
      if (mdCount !== 1) {
        return {success: false, result: 'Exactly one markdown file must be submitted'};
      }
    }
    const formData = new FormData();
    for(let i = 0; i < files.length; i++){
      formData.append('file_' + i, files[i], files[i].name);
    }
    formData.append('title', title);
    formData.append('points', points.toString());
    formData.append('practice', practice.toString());
    return new Promise(resolve => {
      this.server.request('POST', this.server.routeProblemPost, formData).subscribe(data => resolve(data), error => resolve(error.error));
    });
  }

  async edit(id: number): Promise<any> {
    this.setError(false);
    if (this.editId === -1) {
      this.editId = id;
    } else {
      const title: string = (document.getElementById('newtitle') as HTMLInputElement).value;
      const points: number = parseInt((document.getElementById('newpoints') as HTMLInputElement)?.value, 10);
      const files: FileList = (document.getElementById('newDescription') as HTMLInputElement).files;
      if (files.length > 0) {
        localStorage.removeItem("problem-description-" + id);
      }
      const formData = new FormData();
      for(let i = 0; i < files.length; i++){
        formData.append('file_' + i, files[i], files[i].name);
      }
      formData.append('title', title);
      formData.append('points', points.toString());
      formData.append('id', id.toString());

      this.server.request('PUT', this.server.routeProblemPut, formData).subscribe(data => {
        this.ngOnInit();
      }, error => {
        this.setError(true, -1, error.error.result);
      });
      this.editId = -1;
    }
  }
}
