/**
 * Bootstrap types shared between frontend and backend.
 */
export enum BootstrapTypes {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark",
}

/**
 * Announcement sent to teams.
 * Contains a message being sent, the color of the message, and the time it was sent.
 * The time MUST be wrapped in a Date object - otherwise the compiler won't recognize it.
 * This is a valid date though, and wrapping it gives you the correct date.
 */
export interface Announcement {
  message: string,
  messageType: BootstrapTypes,
  time: Date,
}
