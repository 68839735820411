import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-score-button',
  templateUrl: './score-button.component.html',
  styleUrls: ['./score-button.component.scss']
})
export class ScoreButtonComponent implements OnInit {
  @Input() isCheckedOutLocal: any;
  @Input() isCheckedOutElsewhere: any;
  @Output() buttonClicked = new EventEmitter<void>();
  @Input() submission: any;
  @Input() preset = 'scored';

  constructor() { }

  ngOnInit(): void {
  }

  runIsCheckedOutLocal(submissionId): boolean {
    return this.isCheckedOutLocal(submissionId);
  }

  runIsCheckedOutElsewhere(submissionId): boolean {
    return this.isCheckedOutElsewhere(submissionId);
  }
}
