
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChildren, ViewChild} from '@angular/core';
import {ServerService} from '../services/server.service';
import {interval} from 'rxjs';
import { Socket, io } from "socket.io-client";
import { WebsocketIO } from '../services/websockets-io';


const submissionRefreshSource = interval(30000);
const ZERO_ASCII = 48;
const NINE_ASCII = 57;


@Component({
    selector: 'app-submission-list',
    templateUrl: './submission-list.component.html',
    styleUrls: ['./submission-list.component.scss']
})

export class SubmissionListComponent implements AfterViewInit, OnDestroy {

    constructor(public server: ServerService) {
    }

    //submissionSub = submissionRefreshSource.subscribe(val => this.refreshSubmissions());

    socket: WebsocketIO = undefined;

    initialFilterNeeded = true;

    selectedText: string;
    testCounter = 1;
    checkedOutElsewhere = [];
    localCheckouts = [];
    checkedOutAnywhere = [];
    submissions: any[];
    filteredSubmissions: any[];
    @ViewChildren('mdeditor', {read: ElementRef}) inputs;
    @Input() preset = 'unscored';
    @ViewChild('teamFilter') teamFilter: ElementRef;
    @ViewChild('numberFilter') numberFilter: ElementRef;
    @ViewChild('judgeFilter') judgeFilter: ElementRef;

    localCheckoutsKey: string;
    alertFlag = false;
    alertMessage: string;
    alertType: string;

    currentSubmission: any;


    async ngAfterViewInit(): Promise<void> {
      if(this.socket === undefined || this.socket.isClosed()){
        this.socket = new WebsocketIO("submission", () => {
          this.refreshSubmissions();
        });
      }
      this.refreshSubmissions();

      this.localCheckoutsKey = this.preset === 'unscored' ? 'unscoredLocalCheckouts' : 'scoredLocalCheckouts';
      const tempSubmissions = await this.getSubmissions();
      if (localStorage.getItem(this.localCheckoutsKey)){
        this.localCheckouts = JSON.parse(localStorage.getItem(this.localCheckoutsKey));
      }

      for (let submission of tempSubmissions) {
        const available = submission.checkedOut;
        if (available === -1){
          this.checkedOutAnywhere.push(submission.id);
        }
        if ((available === -1) && !this.localCheckouts.includes(submission.id)){
          this.checkedOutElsewhere.push(submission);
        } else if ((available === 1) && this.checkedOutElsewhere.includes(submission.id)){
          this.checkedOutElsewhere.splice(this.checkedOutElsewhere.indexOf(submission.id), 1);
        }
      }

      if (this.checkedOutAnywhere.length < 1){
        this.localCheckouts = [];
        localStorage.setItem(this.localCheckoutsKey, JSON.stringify(this.localCheckouts));
      }

      this.submissions = tempSubmissions;
      this.filteredSubmissions = this.submissions;
    }

    async clearAlert(): Promise<void> {
      this.alertFlag = false;
      this.alertMessage = '';
      this.alertType = '';
    }

  /**
   * @author: Alex Goebel
   * @param str: String where one char needs to be changed
   * @param index: Index of which the char needs to be changed
   * @param replacement: The char that is to replace the existing char
   */
    replaceIndex(str: string, index: number, replacement: string): string {
      return str.substring(0, index) + replacement + str.substring(index + 1);
    }

  /**
   * @author: Alex Goebel
   * @param str: The string of which all non numerical characters are to be replaced by spaces
   * @return string: The string with all of the non numerical characters replaced by spaces
   */
  replaceChars(str: string): string{
      for(let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) < ZERO_ASCII || str.charCodeAt(i) > NINE_ASCII) {
          str = this.replaceIndex(str, i, ' ');
        }
      }
      return str;
    }

  /**
   * @author: Alex Goebel
   * @param str: The string that the numbers are to be parsed from
   * @return number[]: The array of numbers entered by the user
   * This method takes in a string and returns an array of all the numbers
   * contained in the string
   */
  filterNums(str: string): number[]{
    const filteredNumbers: number[] = [];
    const strOfNums = this.replaceChars(str).split(' ');
    for (const s of strOfNums) {
      if (s !== '') {
        filteredNumbers.push(Number(s));
      }
    }
    return filteredNumbers;
  }

  /**
   * @author: Alex Goebel
   * @param: none
   * @return: none
   * This method is called whenever the user enters a key in the input fields
   * it will read what is in the input fields and filter the results based on
   * the team name and the numbers that are inputted
   */
  applyFilter(): void{
      if (this.getPreset() === 'scored') {
        const teamValue = this.teamFilter.nativeElement.value;
        const numberValue = this.numberFilter.nativeElement.value;
        const judgeValue = this.judgeFilter.nativeElement.value;
        this.filteredSubmissions = [];
        for (let k = 0; k < this.submissions.length; k++) {
          if (this.submissions[k].school.toLowerCase().includes(teamValue.toLowerCase()) &&
            (numberValue.includes(this.submissions[k].problem) || numberValue.length === 0) &&
            this.submissions[k].judgeName.toLowerCase().includes(judgeValue.toLowerCase())) {
            this.filteredSubmissions.push(this.submissions[k]);
          }
        }
      } else{
        const teamValue = this.teamFilter.nativeElement.value;
        const numberValue = this.numberFilter.nativeElement.value;
        this.filteredSubmissions = [];
        for (let k = 0; k < this.submissions.length; k++) {
          if (this.submissions[k].school.toLowerCase().includes(teamValue.toLowerCase()) &&
            (numberValue.includes(this.submissions[k].problem) || numberValue.length === 0)) {
            this.filteredSubmissions.push(this.submissions[k]);
          }
        }
      }
    }
    getLangFromExt(ext: string): string{
      let lang: string;
      if (ext.toLowerCase() === 'py'){
        lang = 'Python';
      } else if (ext.toLowerCase() === 'cpp'){
        lang = 'C++';
      } else if (ext.toLowerCase() === 'java'){
        lang = 'Java';
      } else {
        lang = ext;
      }
      return lang;
    }

    async refreshSubmissions(): Promise<void>{
      if (localStorage.getItem(this.localCheckoutsKey)){
        this.localCheckouts = JSON.parse(localStorage.getItem(this.localCheckoutsKey));
      }


      const tempSubmissions = await this.getSubmissions();
      this.applyFilter();
      for (let i = 0; i < tempSubmissions.length; i++){
        const available = tempSubmissions[i].checkedOut;
        if ((available === -1) && !this.localCheckouts.includes(tempSubmissions[i].id)){
          if (!this.checkedOutElsewhere.includes(tempSubmissions[i].id)){
            this.checkedOutElsewhere.push(tempSubmissions[i].id);
          }
        } else if ((available === 1) && this.checkedOutElsewhere.includes(tempSubmissions[i].id)){
          this.checkedOutElsewhere.splice(this.checkedOutElsewhere.indexOf(tempSubmissions[i].id), 1);
        }
      }
      if (tempSubmissions.length !== this.submissions.length){
        this.submissions = tempSubmissions;

      } else {
        for (const submission of tempSubmissions) {
          // eslint-disable-next-line max-len
          if (!this.submissions.find(s => s.id === submission.id && (this.getPreset() === 'unscored' || s.judgedAt === submission.judgedAt))) {
            this.submissions = tempSubmissions;
            break;
          }
        }
      }
      this.applyFilter();
    }

    private async getSubmissions(): Promise<any> {
      if (this.preset === 'unscored'){
        return (await this.getUnscoredSubmissions()).sort((x, y) => (x.id < y.id) ? -1 : ((x.id > y.id) ? 1 : 0));
      }else if (this.preset === 'scored'){
        return (await this.getScoredSubmissions()).sort((x, y) => (x.id > y.id) ? -1 : ((x.id < y.id) ? 1 : 0));      }
      return await new Promise<any[]>(resolve => {
        this.server.request('GET', '/api/submission/all').subscribe((submissions: any) => {
          resolve(submissions.submissions);
       });
      });
    }

  async checkoutButtonClicked(submission: any): Promise<void>{
      this.currentSubmission = undefined;
      if (!this.isCheckedOutElsewhere(submission.id)) {
        this.currentSubmission = submission;
        if ((await this.isCheckedOut(submission.id)) === 1) {
          await this.checkoutSubmission(submission.id);
        }
      }
  }

    async checkoutSubmission(submission: number): Promise<{status: number, result: any}>{
      return new Promise<{status: number, result: any}>(resolve => {
        this.server.request('POST', '/api/submission/checkout/' + submission).subscribe((e: any) => {
          if (e !== -1){
            this.localCheckouts.push(e);
            localStorage.setItem(this.localCheckoutsKey, JSON.stringify(this.localCheckouts));
          }
          resolve(e);
        });
      });
    }

  async releaseSubmission(submission: number): Promise<{status: number, result: any}>{
    return new Promise<{status: number, result: any}>(resolve => {
      this.server.request('POST', '/api/submission/release/' + submission).subscribe((e: any) => {
        if (e !== -1){
          this.localCheckouts.splice(this.localCheckouts.indexOf(submission), 1);
          localStorage.setItem(this.localCheckoutsKey, JSON.stringify(this.localCheckouts));
          this.currentSubmission = undefined;
        }
        resolve(e);
      });
    });
  }

  submitComment(submission: any, verdict: number): void {
    const textInput: HTMLInputElement = document.getElementsByClassName('mdeditor-input')[0] as HTMLInputElement;

    const comment = textInput.value;
    let verdictVal: string;
    if (verdict === 1){
      verdictVal = 'accepted';
    } else if (verdict === 0) {
      verdictVal = 'wrong answer';
    } else {
      verdictVal = 'no verdict';
    }
    if (this.preset === 'scored') {
      this.server.request('POST', '/api/comment/submission/repost/' + submission.id,
        {submissionId: submission.id, comment, verdict: verdictVal, previousVerdict: submission.verdict}).subscribe((e: any) => {
        this.alertFlag = true;
        this.alertMessage = 'Successfully submitted feedback';
        this.alertType = 'success';
      }, (error: any) => {
        this.alertFlag = true;
        this.alertMessage = 'Error submitting feedback';
        this.alertType = 'danger';
      });
    } else {
      this.server.request('POST', this.server.routeCommentPostSpecific + submission.id,
        {submissionId: submission.id, comment, verdict: verdictVal}).subscribe((e: any) => {
        this.alertFlag = true;
        this.alertMessage = 'Successfully submitted feedback';
        this.alertType = 'success';
      }, (error: any) => {
        this.alertFlag = true;
        this.alertMessage = 'Error submitting feedback';
        this.alertType = 'danger';
      });
    }
    textInput.value = '';

    this.releaseSubmission(submission.id);
    localStorage.setItem(this.localCheckoutsKey, JSON.stringify(this.localCheckouts));
    this.refreshSubmissions();

  }

    async isCheckedOut(submission: number): Promise<number>{
      return new Promise<number>(resolve => {
        this.server.request('GET', this.server.routeSubmissionCheckedOutGetSpecific + submission).subscribe((e: any) => {
          resolve(e);
        });
      });
    }

    async getUnscoredSubmissions(): Promise<any[]>{
      return await new Promise<any[]>(resolve => {
        this.server.request('GET', this.server.routeSubmissionGetUnscored).subscribe((submissions: any) => {
          resolve(submissions.submissions);
        });
      });
    }

    async getScoredSubmissions(): Promise<any>{
      return await new Promise<any>(resolve => {
        this.server.request('GET', this.server.routeSubmissionGetScored).subscribe((submissionObj: any) => {
          resolve(submissionObj);
        });
      });
    }
    isCheckedOutLocal = (subNum: number) => {
        if (localStorage.getItem(this.localCheckoutsKey)){
          this.localCheckouts = JSON.parse(localStorage.getItem(this.localCheckoutsKey));
        }
        return this.localCheckouts.includes(subNum);
    }


    isCheckedOutElsewhere = (subNum: number) => {
      return this.checkedOutElsewhere.includes(subNum);
    }

    getPreset(): string{
      return this.preset;
    }

    getFormattedDate(dateStr: string): string{
      // dateStr
      const dateObj = new Date(dateStr);
      return dateObj.toLocaleTimeString() + ', ' + dateObj.toLocaleDateString();
    }

    ngOnDestroy(): void {
      this.socket.closeSocket();
      //this.submissionSub.unsubscribe();
    }
  }
