import {BrowserModule} from '@angular/platform-browser';
import {NgModule, SecurityContext} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ProfileComponent} from './profile/profile.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService, LogoutInterceptor} from './services/auth.service';
import {PracticeProblemsAccordionComponent} from "./practice-problems/practice-problems-accordion.component";
import {ProblemsAccordionComponent} from './problems/problems-accordion.component';
import {ScoreboardComponent} from './scoreboard/scoreboard.component';
import {RegisterJudgeComponent} from './register-judge/register-judge.component';
import {RegisterAdminComponent} from './contest-options/contest-options.component';
import {JudgeViewComponent} from './judge-view/judge-view.component';
import {TeamViewComponent} from './team-view/team-view.component';
import {TempFileStoreModule} from './services/temp-file-store.module';
import {MdeditorComponent} from './mdeditor/mdeditor.component';
import {NewUserComponent} from './new-user/new-user.component';
import {SubmissionListComponent} from './submission-list/submission-list.component';
import {ManageProblemsComponent} from './manage-problems/manage-problems.component';
import {AnnouncementComponent} from './announcement/announcement.component';
import {TeamAnnouncementComponent} from './team-announcement/team-announcement.component';
import {ClockComponent} from "./clock/clock.component";
import {ScoreButtonComponent} from './score-button/score-button.component';
import {FeedbackHistoryComponent} from './feedback-history/feedback-history.component';
import {FeedbackModalComponent} from './feedback-modal/feedback-modal.component';
import {ToasterComponent} from './toaster/toaster.component';
import {ToastComponent} from './toast/toast.component';
import {AboutPageComponent} from "./about-page/aboutpage.component";
import {CodePreviewComponent} from './code-preview/code-preview.component';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-python';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ProblemDescriptionComponent} from "./problem-description/problem-description.component";
import {NgxMdModule} from "ngx-md";
import {FileUploadModule} from "ng2-file-upload";
import {DeleteDatabaseComponent} from "./delete-database/delete-database.component";
import {ProblemsPageComponent} from "./problems-page/problems-page.component";

/**
 * Angular generated module file.
 * Look up angular modules for documentation
 */
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ProfileComponent,
        ProblemsAccordionComponent,
        PracticeProblemsAccordionComponent,
        ProblemsPageComponent,
        RegisterComponent,
        ScoreboardComponent,
        LoginComponent,
        RegisterJudgeComponent,
        RegisterAdminComponent,
        JudgeViewComponent,
        TeamViewComponent,
        MdeditorComponent,
        NewUserComponent,
        SubmissionListComponent,
        ManageProblemsComponent,
        AnnouncementComponent,
        TeamAnnouncementComponent,
        ClockComponent,
        ScoreButtonComponent,
        FeedbackHistoryComponent,
        FeedbackModalComponent,
        ToasterComponent,
        ToastComponent,
        AboutPageComponent,
        CodePreviewComponent,
        ProblemDescriptionComponent,
      DeleteDatabaseComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TempFileStoreModule,
    NgbModule,
    NgxMdModule.forRoot(),
    FileUploadModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutInterceptor,
      multi: true
    },
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
