<main class="form-signin">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <svg class="symbol symbol_logo_full" width="100"><use xlink:href="/assets/img/icons.svg#logo_full"></use></svg>
    <h1 class="h3 mb-3 fw-normal">Welcome to OpComp!</h1>
    <div class="form-floating mb-3">
      <input
        type="text" id="username" placeholder="Username"
        class="form-control {{ formInvalid ? 'is-invalid' : '' }}" formControlName="Username"
      >
      <label for="username">Username</label>
    </div>
    <div class="form-floating mb-2">
      <input
        type="password" id="password" placeholder="Password"
        class="form-control {{ formInvalid ? 'is-invalid' : '' }}" formControlName="Password"
      >
      <label for="password">Password</label>
      <div class="invalid-feedback">{{ message !== undefined ? message : '&nbsp;' }}</div>
    </div>
    <div class="row mb-2 align-items-center justify-content-center">
      <div class="col-6">
        <button class="btn btn-primary w-100" type="submit">
          Sign in
        </button>
      </div>
      <div class="col-6">
        <a routerLink="/new-user" class="btn btn-primary w-100">
          New User
        </a>
      </div>
    </div>
    OR
    <a routerLink="/scoreboard" class="btn btn-primary w-100 mt-2">
      View Standings
    </a>
  </form>
</main>
