import { TeamAnnouncementComponent } from './team-announcement/team-announcement.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { Injectable, NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { ProblemsAccordionComponent } from './problems/problems-accordion.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { RegisterJudgeComponent } from './register-judge/register-judge.component';
import { RegisterAdminComponent } from './contest-options/contest-options.component';
import { JudgeViewComponent } from './judge-view/judge-view.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ScoreboardComponent } from './scoreboard/scoreboard.component';
import { ManageProblemsComponent } from './manage-problems/manage-problems.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AboutPageComponent } from './about-page/aboutpage.component';
import {PracticeProblemsAccordionComponent} from "./practice-problems/practice-problems-accordion.component";
import {ProblemsPageComponent} from "./problems-page/problems-page.component";

/**
 * Determines whether the current user (or guest) can access a page or not
 * @param router Angular Router object
 * @param roles array of roles allowed to access route
 * @returns true or UrlTree to redirect to
 */
function determineCanActivate(
  router: Router,
  roles: string[]
): boolean | UrlTree {
  const helper = new JwtHelperService();
  const user = JSON.parse(localStorage.getItem('user'));

  if (roles === undefined && user !== undefined && user !== null) {
    // if roles is undefined, only allow guests to access. redirect others
    return router.createUrlTree(['/']);
  }

  if (roles === undefined && (user === undefined || user === null)) {
    // if roles is undefined, only allow guests to access.
    return true;
  }

  if (
    user === undefined ||
    user === null ||
    helper.isTokenExpired(user?.token)
  ) {
    // if roles is not undefined but user is not logged in or their token expired, redirect
    return router.createUrlTree(['/']);
  }

  const decodedToken = helper.decodeToken(user.token);
  let roleMatches = false;

  for (const role in roles) {
    if (decodedToken.scope === roles[role]) {
      roleMatches = true;
      break;
    }
  }

  if (!roleMatches) {
    // if role is not allowed, redirect
    return router.createUrlTree(['/']);
  }

  // user can access page
  return true;
}

@Injectable()
class CanActivateAdmin implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return determineCanActivate(this.router, ['admin']);
  }
}

@Injectable()
class CanActivateTeam implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return determineCanActivate(this.router, ['team']);
  }
}

@Injectable()
class CanActivateJudge implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return determineCanActivate(this.router, ['judge', 'admin']);
  }
}

@Injectable()
class CanActivateGuestOnly implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return determineCanActivate(this.router, undefined);
  }
}

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'admin-home',
    component: ScoreboardComponent,
    canActivate: [CanActivateAdmin],
  },
  { path: 'scoreboard', component: ScoreboardComponent },
  { path: 'announcement',
    component: AnnouncementComponent,
    canActivate: [CanActivateAdmin]
  },

  { path: 'team-announcement',
    component: TeamAnnouncementComponent,
    canActivate: [CanActivateTeam],
  },

  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [CanActivateAdmin],
  },
  {
    path: 'problems',
    component: ProblemsPageComponent,
    canActivate: [CanActivateTeam],
  },
  {
    path: 'register-judge',
    component: RegisterJudgeComponent,
    canActivate: [CanActivateAdmin],
  },
  {
    path: 'options',
    component: RegisterAdminComponent,
    canActivate: [CanActivateAdmin],
  },
  {
    path: 'manage-problems',
    component: ManageProblemsComponent,
    canActivate: [CanActivateAdmin],
  },
  {
    path: 'judge',
    component: JudgeViewComponent,
    canActivate: [CanActivateJudge],
  },
  {
    path: 'new-user',
    component: NewUserComponent,
    canActivate: [CanActivateGuestOnly],
  },
  {
    path: 'about',
    component: AboutPageComponent
  },
  {
    path: '**', // 404 catchall, if user is not logged in, renders login page; otherwise, redirects to homepage
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    CanActivateAdmin,
    CanActivateJudge,
    CanActivateTeam,
    CanActivateGuestOnly,
  ],
})
export class AppRoutingModule {}
