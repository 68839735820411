<form (ngSubmit)="onSubmit()">
    
    <label for="fileIn">Please choose a solution file to upload:</label>
    <br>
    <input id="fileIn" name="fileIn" type="file" (change)="handleFileInput($event.target.files)">
    <br>
    
    <br>
    <label>Which question did you answer?</label>
    <br>

    <div *ngFor="let q of validQuestions">
        <input type="radio" name="question" id="{{q}}" value="{{q}}" (change)="handleQuestionSelect($event.target)" ng-model="selectedQuestion">
        <label for="{{q}}">Question {{q}}</label>
        <br>
    </div>

    <br>
    <input type="submit" id="submitButton" disabled="disabled">
    <p>
        {{text}}
    </p>
</form>
