import { Component, OnInit, ViewChild, ElementRef, QueryList, OnDestroy } from '@angular/core';
import { ServerService } from '../services/server.service';
import { TempFileStoreModule } from '../services/temp-file-store.module';

const mdr = require('markdown-it')();

@Component({
  selector: 'app-problems-page',
  templateUrl: './problems-page.component.html',
  styleUrls: ['./problems-page.component.scss']
})

export class ProblemsPageComponent implements OnInit {
  feedbackProblem: number;
  feedbackMessage: string;
  feedbackType: string;

  compStatus: string;

  @ViewChild('problemHeaders') problemHeaders: QueryList<ElementRef>;

  constructor(private server: ServerService) {
  }

  giveFeedback(event: any): void {
    this.feedbackProblem = event.problemId;
    this.feedbackMessage = event.message;
    this.feedbackType = event.type;
  }

  clearFeedback(): void {
    this.feedbackProblem = undefined;
    this.feedbackMessage = undefined;
    this.feedbackType = undefined;
  }

  ngOnInit(): void {
  }

  compStarted() {
    this.clearFeedback();
  }

  compNotYetStarted() {
    this.clearFeedback();
  }

  compEnded() {
    this.clearFeedback();
  }
}
