<div class="accordion mb-1" id="problemsAccordion">
  <div
    id="problemHeaders"
    class="accordion-item"
    *ngFor="let problem of problems"
  >
    <h2 class="accordion-header">
      <button
        (click)="expandedProblem = problem.id"
        class="accordion-button d-flex"
        [ngClass]="{ collapsed: expandedProblem !== problem.id }"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#problem' + problem.id"
      >
        <span class="flex-grow-1">{{ problem.title }}</span>
        <span id="score-{{ problem.id }}">
                <i *ngIf="problem.currentVerdict === 'wrong answer'" style="color: red" class="fa fa-times-circle"></i>
                <i *ngIf="problem.currentVerdict === 'no verdict'" style="color: gray" class="fa fa-minus-circle"></i>
                <i *ngIf="problem.currentVerdict === 'accepted'" style="color: green" class="fa fa-check-circle"></i>
                &nbsp;</span>
        <span class="badge bg-secondary me-2"
        >{{ problem.points }} points</span
        >
      </button>
    </h2>
    <div
      [attr.id]="'problem' + problem.id"
      class="accordion-collapse collapse"
      [ngClass]="{ show: expandedProblem == problem.id }"
      data-bs-parent="#problemsAccordion"
    >
      <div class="accordion-body">
        <div *ngIf="problem.fileLocation">
          <problem-description [problemId]="problem.id"></problem-description>
        </div>
        <p [attr.id]="'status' + problem.id"></p>
        <label
          *ngIf="problem.currentVerdict !== 'accepted'"
          [attr.for]="'submission' + problem.id"
          class="form-label fw-bold"
        >Upload New Submission</label
        >
        <div *ngIf="problem.currentVerdict !== 'accepted'" class="input-group mb-3">
          <input
            class="form-control"
            [ngClass]="{
                    'is-invalid':
                      feedbackType === 'danger' &&
                      feedbackProblem === problem.id,
                    'is-valid':
                      feedbackType === 'success' &&
                      feedbackProblem === problem.id
                  }"
            type="file"
            accept=".java, .py, .c, .cpp, .JAVA, .PY, .C, .CPP"
            [attr.id]="'submission' + problem.id"
            (change)="handleFileInput($event.target.files)"
          />
          <button
            class="btn btn-success"
            type="button"
            [attr.id]="'button' + problem.id"
            [disabled]="stopAllUpload"
            (click)="onUpload(problem.id)"
          >
            <i class="fa fa-cloud-upload"></i> Submit
          </button>
          <div
            *ngIf="
                    feedbackProblem === problem.id &&
                    feedbackMessage !== undefined
                  "
            [ngClass]="{
                    'invalid-feedback': feedbackType === 'danger',
                    'valid-feedback': feedbackType === 'success'
                  }"
          >
            {{ feedbackMessage }}
          </div>
        </div>
        <div class="table-responsive-lg">
          <table
            class="table table-hover"
            *ngIf="problem.submissions?.length > 0"
          >
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" class="w-50">File Name</th>
              <th scope="col">Status</th>
              <th scope="col" class="w-25">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr
              *ngFor="
                        let submission of problem.submissions.slice().reverse();
                        let i = index
                      "
            >
              <td>{{ submission.id }}</td>
              <td>{{ submission.fileName }}</td>
              <td>
                        <span *ngIf="submission.currentVerdict === 'wrong answer'">
                          <i  style="color: red" class="fa fa-times-circle"></i>
                          Rejected
                        </span>
                <span *ngIf="submission.currentVerdict === 'no verdict'">
                          <i style="color: gray" class="fa fa-minus-circle"></i>
                          Ignored
                        </span>
                <span *ngIf="submission.currentVerdict === 'accepted'">
                          <i style="color: green" class="fa fa-check-circle"></i>
                          Accepted
                        </span>
                <span *ngIf="submission.currentVerdict === undefined">
                          Submitted
                        </span>
              </td>
              <td class="text-nowrap">
                <button
                  (click)="download(submission)"
                  class="btn btn-sm btn-success"
                >
                  <i class="fa fa-cloud-download"></i
                  ><span class="d-none d-lg-inline"
                >&nbsp;Download</span
                ></button
                >&nbsp;

                <button
                  id="comments-{{ submission.id }}"
                  disabled
                  class="btn btn-sm btn-primary"
                  (click)="showComments(submission)"
                  data-bs-toggle="modal"
                  [attr.data-bs-target]="
                            '#commentsModal' + submission.id
                          "
                >
                  <i class="fa fa-comment"></i
                  ><span class="d-none d-lg-inline"
                >&nbsp;View Feedback</span
                >
                </button>

                <div
                  class="modal fade"
                  [attr.id]="'commentsModal' + submission.id"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5
                          class="modal-title"
                          id="modalTitle-{{ submission.id }}"
                        ></h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <p id="modalComment-{{ submission.id }}"></p>
                      </div>
                      <div class="modal-footer">
                        <h6
                          class="modal-comments"
                          id="modalFooter-{{ submission.id }}"
                        ></h6>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
