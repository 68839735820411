import { Injectable } from '@angular/core';
import {ServerService} from "./server.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Announcement} from "../models/responseObjects";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  private pollSubject = new BehaviorSubject<Announcement[]>([]);

  private readonly POLL_MILLISECONDS = 10000;

  private lastRequest: Announcement[] = [];

  private readonly ANNOUNCEMENTS_KEY = 'announcements';

  constructor(private server: ServerService) {
    this.initAnnouncements();
    this.startPolling();
  }

  /**
   * Initializes local storage for announcements - ensures page reload doesn't give new notifs
   */
  private initAnnouncements() {
    if (!localStorage.getItem(this.ANNOUNCEMENTS_KEY)) {
      localStorage.setItem(this.ANNOUNCEMENTS_KEY, JSON.stringify(this.lastRequest));
    } else {
      this.lastRequest = JSON.parse(localStorage.getItem(this.ANNOUNCEMENTS_KEY));
    }
  }

  public get receivedAnnouncements(): Announcement[] {
    return this.lastRequest;
  }

  private startPolling(): void {
    this.get().subscribe();
    setInterval(() => {
      this.get().subscribe();
    }, this.POLL_MILLISECONDS)
  }

  /**
   * Returns an observable that emits with the response to a get request
   * for announcements every time one such response is received.
   * Also emits once on subscription with the last value received.
   */
  public get pollObservable(): Observable<Announcement[]> {
    return this.pollSubject.asObservable();
  }

  /**
   * Makes a Get request for the announcements
   * @return Observable that sends the get request and updates local storage when subscribed to
   */
  private get(): Observable<any> {
    return this.server.request('GET', this.server.routeMessageGetAll).pipe(tap(response => {
      this.pollSubject.next(response);
      localStorage.setItem(this.ANNOUNCEMENTS_KEY, JSON.stringify(this.lastRequest));
      this.lastRequest = response;
    }));
  }

  /**
   * Resets all local announcements
   * ONLY USE FOR DEBUGGING - THIS SHOULD NEVER APPEAR OTHERWISE
   */
  private _debugResetAnnouncements() {
    localStorage.setItem(this.ANNOUNCEMENTS_KEY, JSON.stringify([]));
    this.lastRequest = [];
    this.initAnnouncements();
  }
}
