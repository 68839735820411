import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html' ,
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  form: UntypedFormGroup;

  formInvalid: boolean;
  message: string;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user != null) {
      if (user.type === 'admin') {
        this.router.navigateByUrl('/admin-home');
      } else if (user.type === 'judge') {
        this.router.navigateByUrl('/judge');
      } else if (user.type === 'team') {
        this.router.navigateByUrl('/problems');
      }
    }

    this.form = this.fb.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required]
    });
  }

  async onSubmit(): Promise<void> {
    this.formInvalid = false;
    if (this.form.valid) {
      try {
        await this.authService.login(this.form.value, (error) => {
          // console.log(error);
          if (error.error.notYetRegistered) {
            this.router.navigate(["/new-user"], {state: {transferredLogin: this.form.value}});
          } else if (error.error.type !== undefined) {
            this.formInvalid = true;
            this.message = 'Failed to communicate with server.';
          } else {
            this.formInvalid = true;
            this.message = error.error.message;
          }
        });
      } catch (err) {}
    } else {
      this.formInvalid = true;
      this.message = 'Please fill in username and password.';
    }
  }

  newUser(): void {
    this.router.navigate(['/new-user']);
  }
}
