import { Injectable } from '@angular/core';

import { Socket, io } from "socket.io-client";
import { baseUrl } from '../services/server.service';

@Injectable({
    providedIn: 'root',
})
export class WebsocketIO{
    private socket: Socket = null;
    private token;
    private callback: () => void;

    constructor(channel: string, callback: () => void, maxDelay : number = 3000) {
        this.callback = callback;
        this.socket = io(baseUrl);
        this.socket.on(channel, (msg) => {
            if(msg === "_should_update_now_"){
                const randomVal = Math.random() * maxDelay;
                //console.log("Ping on channel " + channel + " recevied, waiting to ping.\nStarting at time " + Date.now() + "\nDelay should be " + randomVal + "ms.");
                this.sleep(randomVal).then(() => {
                    callback();
                    //console.log(`Ping on channel ${channel} now called.\nEnded at time ${Date.now()}`)
                });
            }
        })
    }

    private sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private getIOSocket(): Socket {
        if(this.socket === null || this.socket.disconnected){
            this.token = JSON.parse(localStorage.getItem('user'));
            this.socket = io(baseUrl, {
                query: this.token
            })
        }
        return this.socket;
    }

    public isClosed(): boolean {
        return this.socket === null || this.socket.disconnected;
    }

    public closeSocket(): void {
        if(this.socket !== null || !this.socket.disconnected){
            this.socket.close();
        }
    }
}