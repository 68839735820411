<div class="modal fade" [id]="'scoringModal'+preset" tabindex="-1"
     aria-labelledby="scoringModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div *ngIf="submission !== undefined && !runIsCheckedOutElseWhere(); else error" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button (click)="submissionReleased.emit({submission: submission}); submitted()" type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 d-flex flex-column">
            <div>
              <h8><b>Team: {{submission.school}}</b></h8><br>
              <h8><b>Problem: {{submission.problem}}</b></h8><br>
              <h8 *ngIf="preset === 'scored'">
                <span *ngIf="submission.verdict == 2" class="badge bg-success">Passed</span>
                <span *ngIf="submission.verdict == 1" class="badge bg-secondary">Ignored</span>
                <span *ngIf="submission.verdict == 3" class="badge bg-danger">Failed</span>
              </h8>
            </div>
            <div class="mt-auto w-100">
              <button (click)="download(submission)" class="btn btn-left rounded btn-primary w-100"
                      type="button"><i class="fa fa-cloud-download"></i> Download</button>
            </div>
          </div>
          <div class="col-md-8">
            <app-feedback-history class="mx-1" [submission]="submission"></app-feedback-history>
            <app-code-preview class="mx-1" [submission]="submission"></app-code-preview>
            <app-mdeditor [existingText]="getExistingText()" [id]="'s' + submission.id" (textChanged)="feedbackTextChanged($event.text)"></app-mdeditor>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="submissionReleased.emit({submission: submission}); submitted()" class="btn btn-warning"
                data-bs-dismiss="modal">Cancel</button>
        <button type="button" (click)="submissionIgnored.emit({submission: submission}); submitted()" class="me-auto btn btn-secondary"
                data-bs-dismiss="modal">Ignore</button>
        <button type="button" (click)="submissionPassed.emit({submission: submission}); submitted()" class="btn btn-success"
                data-bs-dismiss="modal">Pass</button>
        <button type="button" (click)="submissionFailed.emit({submission: submission}); submitted()" class="btn btn-danger"
                data-bs-dismiss="modal">Fail</button>
      </div>
    </div>
    <ng-template #error>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 d-flex flex-column">
              <p *ngIf="!isCheckedOutElseWhere; else checkedOut">There is currently no submission selected</p>
              <ng-template #checkedOut>
                <p>This submission is checked out by another judge. Close this window and choose another submission</p>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </ng-template>
  </div>
</div>
