import {Injectable} from '@angular/core';
import { Papa } from 'ngx-papaparse'

@Injectable({
  providedIn: 'root'
})
export class CSVService {

  constructor(private parser: Papa) {
  }

  public readonly problemNameIndex = 0;
  public readonly problemPointIndex = 1;
  public readonly problemPracticeIndex = 2;
  parseProblems(contents: string): string[][] {
    return this.parser.parse(contents, {skipEmptyLines: true}).data;
  }

  public readonly teamSchoolIndex = 0;
  public readonly teamNameIndex = 1;
  parseTeams(contents: string): string[][] {
    return this.parser.parse(contents, {skipEmptyLines: true}).data;
  }
}
