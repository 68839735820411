<nav class="navbar navbar-expand-lg navbar-dark bg-msoe fixed-top">
  <div class="container">
    <a class="navbar-brand" routerLink="/">MSOE OpComp</a>
    <button
      class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#dropdown" aria-controls="dropdown"
      *ngIf="this.showNavigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="dropdown" *ngIf="!this.showNavigation">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link {{ this.active('/about') }}" aria-current="page" routerLink="/about">About</a>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse" id="dropdown" *ngIf="this.showNavigation">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="this.userType === 'admin'">
        <li class="nav-item">
          <a class="nav-link {{ this.active('/admin-home') }}" aria-current="page" routerLink="/admin-home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/about') }}" aria-current="page" routerLink="/about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/register') }}" aria-current="page" routerLink="/register">Teams</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/register-judge') }}" aria-current="page" routerLink="/register-judge">Judges</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/manage-problems') }}" aria-current="page" routerLink="/manage-problems">Problems</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/options') }}" aria-current="page" routerLink="/options">Contest Options</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/judge') }}" aria-current="page" routerLink="/judge">Submissions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/announcement') }}" aria-current="page" routerLink="/announcement">Announcements</a>
        </li>
      </ul>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="this.userType === 'judge'">
        <li class="nav-item">
          <a class="nav-link {{ this.active('/judge') }}" aria-current="page" routerLink="/judge">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/about') }}" aria-current="page" routerLink="/about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/scoreboard') }}" aria-current="page" routerLink="/scoreboard">Standings</a>
        </li>
      </ul>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="this.userType === 'team'">
        <li class="nav-item">
          <a class="nav-link {{ this.active('/problems') }}" aria-current="page" routerLink="/problems">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/about') }}" aria-current="page" routerLink="/about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/scoreboard') }}" aria-current="page" routerLink="/scoreboard">Standings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{ this.active('/team-announcement') }}" (click)="updateReadAnnouncements()" aria-current="page" routerLink="/team-announcement">Announcements
            <span id="announcementNotification" class="badge bg-white text-black" *ngIf="this.unreadAnnouncements > 0">{{this.unreadAnnouncements}}</span>
          </a>
        </li>
      </ul>

      <div class="dropdown">
        <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">{{ this.userType.charAt(0).toUpperCase() + this.userType.slice(1) }}</a>
        <div class="dropdown-menu">
          <a style="color: #b91616!important" onMouseOver="this.style.color='#b91616'" routerLink="" class="nav-link" (click)="this.authService.logout()">Logout</a>
        </div>
      </div>

    </div>
  </div>
</nav>
