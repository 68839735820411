import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  form: UntypedFormGroup;

  formInvalid: boolean;
  message: string;

  transferredLogin?: any;


  constructor(private authService: AuthService, private router: Router, private renderer: Renderer2) {
    this.transferredLogin = this.router.getCurrentNavigation()?.extras.state?.transferredLogin;
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      Username: new UntypedFormControl('', [Validators.required]),
      Password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      ConfirmPassword: new UntypedFormControl('', [Validators.required]),
    });
    if (this.transferredLogin) {
      this.transferredLogin.ConfirmPassword = "";
      this.form.setValue(this.transferredLogin);
      this.message = "Please confirm your password to register.";
      this.renderer.selectRootElement("#confirmPassword").focus();
      this.formInvalid = true;
    }
  }

  /**
   * Form Submit Event Handler
   */
  async onSubmit(): Promise<void> {
    this.formInvalid = false;
    this.message = undefined;
    if (this.form.valid) {
      const data = this.form.getRawValue();
      if (data.Password !== data.ConfirmPassword) {
        this.formInvalid = true;
        this.message = 'Passwords do not match.';
        return;
      }
      const result = await this.authService.setPassword(data.Username, data.Password);
      if (result.success) {
        document.getElementsByTagName('form')[0].innerHTML = result.message;
        setTimeout(() => {
          this.authService.login({
            Username: data.Username,
            Password: data.Password
          }, (error) => {
            this.ngOnInit();
            this.formInvalid = true;
            this.message = error.error;
          });
        }, 1500);
      } else {
        this.formInvalid = true;
        this.message = result.message;
      }
    } else {
      this.formInvalid = true;
      if (!this.form.controls.Password.invalid) {
        this.message = 'All fields are required.';
      }  else {
        this.message = 'Password must have at least 6 characters.';
      }
    }
  }
}
