<div class="container">
  <div class="card">
    <div class="card-body">
     <h1 class="display-5">Contest Options</h1>
      <div class="row">
        <div class="col-12 col-lg-6 mb-3 mb-lg-0">
          <form [formGroup]="form" (ngSubmit)="this.onSubmit()">
            <div class="mb-3">
              <label for="current" class="form-label">Current Time</label>
              <input
                type="datetime-local" class="form-control" id="current" disabled
                value="{{ currentTime | date: 'yyyy-MM-ddTHH:mm:ss' }}"
              >
            </div>
            <div class="mb-3">
              <label for="start" class="form-label">
                Start Time
                <span class="text-danger" *ngIf="form.controls.startDateInput.errors?.dateAfter !== null">
                  Start time should be before end time
                </span>
              </label>
              <input
                type="datetime-local" class="form-control {{ form.controls.startDateInput.invalid ? 'is-invalid' : ''}}"
                id="start" formControlName="startDateInput"
              >
            </div>
            <div class="mb-3">
              <label for="end" class="form-label">End Time</label>
              <input
                type="datetime-local" class="form-control {{ form.controls.endDateInput.invalid ? 'is-invalid' : ''}}"
                id="end" formControlName="endDateInput"
              >
            </div>
            <div class="mb-3">
              <label for="hide" class="form-label">Hide Scoreboard Time</label>
              <input
                type="datetime-local" class="form-control {{ form.controls.hide.invalid ? 'is-invalid' : ''}}"
                id="hide" formControlName="hide"
              >
            </div>
            <button type="submit" class="btn btn-web-4" [disabled]="this.form.disabled">Save</button>
          </form>
        </div>
        <div class="col-12 col-lg-6 mb-0">
          <p>{{ status }}</p>
          <p class="mb-0">Scoreboard Visibility Option:</p>
          <div class="btn-group mb-3">
            <button class="btn btn-primary {{ scoreboardOption === 'show' ? 'active' : '' }}" (click)="updateScoreboard('show')">Show</button>
            <button class="btn btn-primary {{ scoreboardOption === 'hide' ? 'active' : '' }}" (click)="updateScoreboard('hide')">Hide</button>
            <button class="btn btn-primary {{ scoreboardOption === 'timer' ? 'active' : '' }}" (click)="updateScoreboard('timer')">Time Based</button>
          </div>
          <p class="mb-0">{{ hiddenStatus }}</p>
        </div><div class="col-12 col-lg-6 mb-0">
        <p>{{ status }}</p>
        <p class="mb-0">Database Options:</p>
        <app-delete-database></app-delete-database>
        <!--app-free-all-subs></app-free-all-subs-->
      </div>
      </div>
    </div>
  </div>
</div>
