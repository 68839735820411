import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { validateLocaleAndSetLanguage } from 'typescript';
import { AuthService } from '../services/auth.service';
import { Team } from '../scoreboard/team';
import { ServerService } from '../services/server.service';
import {CSVService} from '../services/csv.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  selectedFiles: FileList;
  fileText: string;

  form: UntypedFormGroup;
  teams: any[];

  confirm: number;

  feedbackProblem: number;
  feedbackMessage: string;
  feedbackType: string;
  stopTeamRegister = false;

  constructor(
    private fb: UntypedFormBuilder,
    private server: ServerService,
    private router: Router,
    private authService: AuthService,
    private csv: CSVService,
  ) {
    this.form = new UntypedFormGroup({
      school: new UntypedFormControl(),
      username: new UntypedFormControl()
    });

    this.form.get('school').setValidators(Validators.required);
    this.form.get('username').setValidators(Validators.required);
  }

  async ngOnInit(): Promise<void> {
    this.confirm = -1;
    this.teams = await this.getTeams();
    // console.log(this.teams);
  }

  private async getTeams(): Promise<any[]> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return await new Promise<any[]>(resolve => {
      this.server.request('GET', this.server.routeTeamGetAll).subscribe(response => {
        resolve(response);
      },
        (error) => {
          this.feedbackProblem = -1;
          this.feedbackMessage = `Error: ${error.error}`;
          this.feedbackType = 'danger';
        });
    });
  }
// TODO - Refactoring needed to get OUT OF THE DOM
  async onSubmit(): Promise<void> {
    if (!this.form.valid) {
      this.feedbackProblem = -1;
      this.feedbackMessage = 'Form not valid: Please check that fields are correctly filled in';
      this.feedbackType = 'danger';
      return;
    }

    const schoolElement = document.getElementById('school') as HTMLInputElement;
    const usernameElement = document.getElementById('username') as HTMLInputElement;

    if (this.teams.some(team => team.username === usernameElement.value)) {
      this.feedbackProblem = -1;
      this.feedbackMessage = 'Duplicate Username: Team usernames must be unique';
      this.feedbackType = 'danger';
      return;
    }

    this.stopTeamRegister = true;
    this.server.request('POST', this.server.routeRegisterTeamPost, {
      Name: schoolElement.value,
      Username: usernameElement.value
    }).subscribe(async () => {
      this.form.get('school').setValue(undefined);
      this.form.get('username').setValue(undefined);
      schoolElement.focus();

      await this.ngOnInit();
      this.clearFeedback();
    }, error => {
      this.feedbackProblem = -1;
      this.feedbackMessage = `Catastrophic Server Error: ${error.message}`;
      this.feedbackType = 'danger';
    }).add(() => this.stopTeamRegister = false)
  }

  async deletePassword(username: string): Promise<void> {
    const result = await this.authService.setPassword(username, null);
    if (result.success) {
      await this.ngOnInit();
      // this.feedbackProblem = -1;
      // this.feedbackMessage = 'Successfully deleted password.';
      // this.feedbackType = 'success';
    } else {
      this.feedbackProblem = -1;
      this.feedbackMessage = 'Failed to delete password. (' + result.message + ')';
      this.feedbackType = 'danger';
    }
  }

  handleFileInput(files: FileList): void {
    this.selectedFiles = files;
  }

  async onUpload(): Promise<void> {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (this.selectedFiles[i].name.split('.').reverse()[0] !== 'csv') {
        this.feedbackProblem = -1;
        this.feedbackMessage = 'Upload failed: file was not a csv file';
        this.feedbackType = 'danger';
        return;
      }

      let newTeams: string[][] = [];
      try {
        newTeams = this.csv.parseTeams(await this.selectedFiles[i].text()).map(team => team.map(field => field.trim()));
      } catch (error) {
        this.feedbackProblem = -1;
        this.feedbackMessage = `Upload failed - ${error.message}. See console for complete details.`;
        this.feedbackType = 'danger';
        console.log('CSV parse error:\n' + JSON.stringify(error));
        return;
      }
      let failedTeams = 0;
      for (const newTeam of newTeams) {
        if (newTeam.length !== 2) {
          failedTeams++;
          continue;
        }
        if (this.teams.some(realTeam => {return newTeam[this.csv.teamNameIndex] === realTeam.username;})) {
          failedTeams++;
          continue;
        }
        const id = await this.server.request('POST', this.server.routeRegisterTeamPost, {
          Name: newTeam[this.csv.teamSchoolIndex],
          Username: newTeam[this.csv.teamNameIndex],
        }).toPromise();
        this.teams.push({id, school: newTeam[this.csv.teamSchoolIndex],
          username: newTeam[this.csv.teamNameIndex], activated: false});
      }
      if (failedTeams > 0) {
        this.feedbackProblem = -1;
        this.feedbackMessage = `Failed to parse ${failedTeams} lines\
        - only ${newTeams.length - failedTeams} teams added`;
        this.feedbackType = 'danger';
      } else {
        this.feedbackProblem = -1;
        this.feedbackMessage = `${newTeams.length} teams successfully added`;
        this.feedbackType = 'success';
      }
      await this.ngOnInit();
    }
  }

  clearFeedback(): void {
    this.feedbackProblem = undefined;
    this.feedbackMessage = undefined;
    this.feedbackType = undefined;
  }
}
