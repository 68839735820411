import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ServerService} from '../services/server.service';

import { SubmissionListComponent } from '../submission-list/submission-list.component';

@Component({
  selector: 'app-judge-view',
  templateUrl: './judge-view.component.html',
  styleUrls: ['./judge-view.component.scss']
})


/**
 * Class for viewing the judge's submissions and allows the judge
 * to download the submissions form the dynamically created table
 */
export class JudgeViewComponent implements AfterViewInit {
  selectedText: string;
  testCounter = 1;
  submissions: any[];
  @ViewChild('unscored', {read: SubmissionListComponent}) unscored_list;
  @ViewChild('scored', {read: SubmissionListComponent}) scored_list;



  constructor(public server: ServerService) {
  }

  async ngAfterViewInit(): Promise<void>{
    this.setDefaultFilters();
  }

  setDefaultFilters(): any{
    this.unscored_list.filters = {preset: "unscored"};
    this.scored_list.filters = {preset: "scored"};
  }

  updateFilters(): any{

  }

}

