import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServerService } from '../services/server.service';
import {ProblemsAccordionComponent} from "../problems/problems-accordion.component";


@Component({
  selector: 'app-practice-problems-accordion',
  templateUrl: './practice-problems-accordion.component.html',
  styleUrls: ['./practice-problems-accordion.component.scss']
})

export class PracticeProblemsAccordionComponent extends ProblemsAccordionComponent implements OnInit, OnDestroy {
  constructor(server: ServerService) {
    super(server);
    this.problemsURL = server.routeProblemGetAllPractice;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async resetPractice() {
    await this.server.request('DELETE', this.server.routeProblemDeletePractice).subscribe(response => {
        this.reloadProblems()
      },
      (_) => {
        this.giveFeedback(-2, 'An error occurred resetting the practice problems.', 'danger');
      })
  }

  hasPracticeSubmissions(): boolean {
    return this.problems.some(p => p.submissions?.length > 0)
  }
}
