import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastEvent, ToastService} from 'src/app/services/toast.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {

  currentToasts: ToastEvent[] = [];
  queuedToasts: ToastEvent[] = [];

  readonly MAX_TOASTS_VISIBLE = 5;


  constructor(private toastService: ToastService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscribeToToastService();
  }

  subscribeToToastService() {
    this.toastService.toastEvents.subscribe((toasts) => {
      //If the queue is too big, move it out.
      if (this.currentToasts.length >= this.MAX_TOASTS_VISIBLE) {
        this.queuedToasts.unshift({
          type: toasts.type,
          title: toasts.title,
          message: toasts.message
        })
      } else {
        this.currentToasts.push({
          type: toasts.type,
          title: toasts.title,
          message: toasts.message,
        });
      }
      //Needed to update component when the list is added to.
      this.changeDetectorRef.detectChanges();
    });
  }

  dispose(index: number) {
    this.currentToasts.splice(index, 1);
    if (this.queuedToasts.length !== 0) {
      this.currentToasts.push(this.queuedToasts.pop())
      this.changeDetectorRef.detectChanges();
    }
  }
}
