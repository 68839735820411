<div class="container">
  <div
    class="alert alert-{{ feedbackType }} alert-dismissible "
    *ngIf="feedbackProblem === -1"
  >
    {{ feedbackMessage }}
    <button type="button" class="btn-close" (click)="clearFeedback()"></button>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="display-5 mb-0">2024 MSOE OpComp Problems</div>
      <app-clock
        (compStarted)="compStarted()"
        (compNotYetStarted)="compNotYetStarted()"
        (compEnded)="compEnded()"
        (stateChanged)="compStatus = $event"></app-clock>

      <p>
        Please ensure that you are following the
        <a href="https://faculty-web.msoe.edu/hasker/opcomp/rules.shtml"
          >rules</a
        >.
      </p>
      <div
        class="alert alert-{{ feedbackType }} alert-dismissible fade show"
        *ngIf="feedbackProblem === -2"
      >
        {{ feedbackMessage }}
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
        ></button>
      </div>
      <app-practice-problems-accordion (giveFeedbackEmitter)="giveFeedback($event)"
                                       (clearFeedbackEmitter)="clearFeedback()"
                                       *ngIf="compStatus === 'waiting to start'"></app-practice-problems-accordion>
      <app-problems-accordion
        (giveFeedbackEmitter)="giveFeedback($event)"
        (clearFeedbackEmitter)="clearFeedback()"
        *ngIf="compStatus === 'started'"></app-problems-accordion>
    </div>
  </div>
</div>
