<div *ngIf="comments.length > 0" class="accordion">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#history-accordion-' + submission.id" aria-expanded="false">
        Feedback History
      </button>
    </h2>
    <div [id]="'history-accordion-' + submission.id" class="accordion-collapse collapse" aria-labelledby="headingOne">
      <div class="accordion-body">
        <div class="accordion accordion-flush">
          <div *ngFor="let comment of comments; let i = index" class="accordion-item">
            <h2 class="accordion-header">
              <button [disabled]="comment.content === ''" class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#history-item-' + comment.id"
                      aria-expanded="false">
                <i *ngIf="comment.verdictObject.verdictName === 'wrong answer'" style="color: red" class="fa fa-times-circle"></i>
                <i *ngIf="comment.verdictObject.verdictName === 'no verdict'" style="color: gray" class="fa fa-minus-circle"></i>
                <i *ngIf="comment.verdictObject.verdictName === 'accepted'" style="color: green" class="fa fa-check-circle"></i>
                &nbsp;Comment #{{i}}
              </button>
            </h2>
            <div [id]="'history-item-'+comment.id" class="accordion-collapse collapse" aria-labelledby="headingOne">
              <div class="accordion-body inner" [innerHTML]="render(comment.content)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
