import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ServerService} from '../services/server.service';

const mdr = require('markdown-it')();

@Component({
  selector: 'app-feedback-history',
  templateUrl: './feedback-history.component.html',
  styleUrls: ['./feedback-history.component.scss']
})
export class FeedbackHistoryComponent implements OnInit, OnChanges {
  @Input() submission: any;
  comments = [];

  constructor(public server: ServerService) { }

  ngOnInit(): void {
    this.populateComments();
  }

  ngOnChanges(): void {
    this.comments = [];
    this.populateComments();
  }

  async populateComments(): Promise<void> {
    const tempComments = await this.retrieveComments();

    for (const comment of tempComments) {
      if (this.comments.find(c => comment.id === c.id) === undefined) {
        this.comments.push(comment);
      }
    }
  }

  async retrieveComments(): Promise<any[]> {
    return this.server.request('GET', this.server.routeCommentGetSpecific + this.submission.id).toPromise();
  }

  render(text: string): string {
    return mdr.render(text);
  }
}
