import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.scss'],
})
export class ScoreboardComponent implements OnInit, OnDestroy {
  teams: object[];
  problems: object[];

  feedbackProblem: number;
  feedbackMessage: string;
  feedbackType: string;

  startTime: Date;
  endTime: Date;
  scoreboardHideTime: Date;
  scoreboardShown: boolean;
  currentTime: Date;

  timeKeys = ["days", "hours", "minutes", "seconds"];
  timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  log(val, val2) { console.log(val + " " + val2); }

  interval: NodeJS.Timeout;

  constructor(public router: Router, private server: ServerService) {
    this.interval = setInterval(() => {
      this.ngOnInit();
    }, 1000);
  }

  async ngOnInit(): Promise<void> {
    this.problems = await this.getProblems();
    this.problems.sort((p1: any, p2: any) => p1.order - p2.order)
    this.server.request('GET', this.server.routeTimeGet).subscribe(
      response => {
        this.startTime = new Date(response.start);
        this.endTime = new Date(response.end);
        this.scoreboardShown = response.show;
        this.currentTime = new Date(response.serverTime);
        this.timeLeft = this.transform(this.endTime.getTime() - this.currentTime.getTime());
      }
    );

    this.teams = await this.getTeams();

    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });
    this.teams.forEach((t: any) => {
      t.lastAcceptedSubmissionTime = new Date(t.lastAcceptedSubmissionTime);
      if (t.hasAcceptedSolution) {
        t.lastAcceptedSubmissionTimeString = formatter.format(t.lastAcceptedSubmissionTime);
      } else {
        t.lastAcceptedSubmissionTimeString = "";
      }
    });

    this.teams.sort((a: any, b: any) => {
      if (b.score !== a.score) {
        return b.score - a.score;
      }
      // Add additional sorting parameters here if necessary
      const timeA = a.lastAcceptedSubmissionTime;
      const timeB = b.lastAcceptedSubmissionTime;
      return (timeA >= timeB) ? 1 : -1;
    });


  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  private async getTeams(): Promise<any[]> {
    return await new Promise<any[]>((resolve) => {
      this.server.request('GET', '/api/contest/scoreboard').subscribe(
        (response) => {
          if (response.success) {
            resolve(response.scores);
          } else {
            resolve([]);
          }
          resolve(response);
        },
        (error) => {
          this.feedbackProblem = -1;
          this.feedbackMessage = `Error: ${error.error}`;
          this.feedbackType = 'danger';
        }
      );
    });
  }

  private async getProblems(): Promise<any[]> {
    return this.server.request('GET', this.server.routeProblemGetAllReal).toPromise()
      .catch(error => {
        this.feedbackProblem = -2;
        this.feedbackMessage = 'An error occurred loading the problems.';
        this.feedbackType = 'danger';
      });
  }

  transform(time: number): any  {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return {days, hours, minutes, seconds};
  }

  protected readonly Date = Date;
}
