import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServerService} from '../services/server.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {
  @Input() submission: any;
  @Input() preset: string;
  @Input() isCheckedOutElseWhere: any;
  @Output() submissionPassed = new EventEmitter<any>();
  @Output() submissionIgnored = new EventEmitter<any>();
  @Output() submissionFailed = new EventEmitter<any>();
  @Output() submissionReleased = new EventEmitter<any>();


  constructor(public server: ServerService) { }

  ngOnInit(): void {
  }

  download(submission: any): void {
    this.server.request('GET', this.server.routeSubmissionGetSpecific + submission.id + '?withCode=true')
      .subscribe((result: any) => {
        const blob = new Blob([result.code]);
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = result.fileName;
        anchor.href = url;
        anchor.click();
    });
  }

  runIsCheckedOutElseWhere(): boolean {
    return this.isCheckedOutElseWhere(this.submission.id);
  }

  submitted(): void {
    const key = JSON.stringify('comment_' + this.submission?.id);
    localStorage.removeItem(key);
  }

  getExistingText(): string {
    const key = JSON.stringify('comment_' + this.submission?.id);
    const existing = localStorage.getItem(key);
    return existing !== null ? existing : '';
  }

  feedbackTextChanged(text: string): void {
    const key = JSON.stringify('comment_' + this.submission?.id);
    localStorage.setItem(key, text);
  }
}
