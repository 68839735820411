<div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">RESET DATABASE</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click'); deletingStatus = 'safe'"></button>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to reset the database? All user data, scores, and submissions will be DELETED forever.</p>
    </div>
    <div class="modal-footer justify-content-start">
      <p>Type DELETE in all caps to reset the database.</p>
      <div class="input-group">
        <input (keyup)="inputChanged($event)" type="text" class="form-control">
        <div class="input-group-append">
          <button type="button" class="btn btn-danger" [disabled]="!ableToDelete" (click)="modal.dismiss(); resetDatabase()">RESET</button>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="btn-group mb-3">
    <button *ngIf="deletingStatus === 'safe'" class="btn btn-warning" (click)="startDeleteDatabase()">Reset Database</button>
    <div *ngIf="deletingStatus === 'started'" class="input-group">
      <button class="btn btn-danger" (click)="open(content)">Are you sure?</button>
      <button class="btn-warning btn" (click)="deletingStatus = 'safe'">X</button>
    </div>
  </div>
</div>
