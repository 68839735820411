import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

export const baseUrl = `https://msoeopcomp.org:10101`;

/**
 * This file handles the server requests between the frontend and backend.
 */
@Injectable({
  providedIn: 'root'
})
export class ServerService {

  /**
   * All routes a preceded with 'route'.
   * The word(s) word describes what value is being interacted with.
   * After is what type of request it should be sent with.
   * (ie. get, post, delete, etc.)
   *
   * Any words after follow the following standard:
   *  'specific' means that an id must be concatenated to the end.
   *  'all' means that the server responds with all in the field.
   *  Any specifier (scored, for example) means that the server responds with all matching the specifier.
   */
  public readonly routeMessagePost = '/api/message';
  public readonly routeMessageGetAll = '/api/message/all';

  public readonly routeTimeGet = '/api/contest/time';
  public readonly routeScoreboardGet = '/api/contest/scoreboard';
  public readonly routeResetPut = '/api/contest/reset';

  public readonly routeTeamGetAll = '/api/team/all';

  public readonly routeJudgeGetAll = '/api/judge/all';

  public readonly routeCommentGetSpecific = '/api/comment/submission/';
  public readonly routeCommentPostSpecific = '/api/comment/submission/';

  public readonly routeSubmissionGetSpecific = '/api/submission/';
  public readonly routeSubmissionPost = '/api/submission';
  public readonly routeSubmissionGetUnscored = '/api/submission/unscored/';
  public readonly routeSubmissionGetScored = '/api/submission/scored/';

  public readonly routeProblemGetAll = '/api/problem/all';
  public readonly routeProblemGetAllReal = '/api/problem/all/real';
  public readonly routeProblemGetAllPractice = '/api/problem/all/practice';
  public readonly routeProblemPut = '/api/problem';
  public readonly routeProblemDelete = '/api/problem';
  public readonly routeProblemDeletePractice = '/api/problem/practice/';
  public readonly routeProblemPost = '/api/problem';
  public readonly routeProblemDescription = '/api/problem/description/';
  public readonly routeProblemDescriptionCurrent = '/api/problem/description/current/';

  public readonly routeProfileGet = '/api/profile';

  public readonly routeRegisterTeamPost = '/api/register';
  public readonly routeRegisterJudgePost = '/api/register-judge';

  public readonly routeSubmissionCheckedOutGetSpecific = '/api/submission/isCheckedOut/';


  private loggedIn = false;
  private token: string;

  constructor(private http: HttpClient) {
  }

  setLoggedIn(loggedIn: boolean, token?: string): void {
    this.loggedIn = loggedIn;
    this.token = token;
  }


  request(method: string, route: string, data?: any): Observable<any> {
    if (method === 'GET') {
      return this.get(route, data);
    }
    const header = (this.loggedIn) ? {Authorization: `Bearer ${this.token}`} : undefined;
    return this.http.request(method, baseUrl + route, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: header
    });
  }


  get(route: string, data?: any): Observable<any> {
    const header = (this.loggedIn) ? {Authorization: `Bearer ${this.token}`} : undefined;

    let params = new HttpParams();
    if (data !== undefined) {
      Object.getOwnPropertyNames(data).forEach(key => {
        params = params.set(key, data[key]);
      });
    }
    return this.http.get(baseUrl + route, {
      responseType: 'json',
      headers: header,
      params
    });
  }
}
