<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="display-5" *ngIf="!((teams?.length === 0 || problems?.length === 0) && (currentTime >= startTime && currentTime <= endTime))">
        {{ currentTime < endTime ? 'Unofficial' : 'Final' }}
        Team Standings
      </div>
      <div *ngIf="scoreboardShown">
        <p class="lead mb-0" *ngIf="(currentTime < endTime) && (currentTime >= startTime)">Time left in the competition:
          <span *ngFor="let key of timeKeys">
            <span *ngIf="timeLeft[key] !== 0">
              {{timeLeft[key]}} {{key}}
            </span>
          </span>
        </p>
      </div>
      <div *ngIf="!scoreboardShown">
        <div class="lead mb-0 display-1 text-center" *ngIf="(currentTime < endTime) && (currentTime >= startTime)">
          <u>Time left in the competition</u><br/>
          <span *ngFor="let key of timeKeys">
            <span *ngIf="timeLeft[key] !== 0">
              {{timeLeft[key]}} {{key}}<br/>
            </span>
          </span>
        </div>
      </div>
        <div class="row mt-3" *ngIf="problems?.length !== 0 && teams?.length !== 0">
        <div class="overflow-x-auto col-xl-9 col-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="col-md-2">Team Name</th>
                <th class="col-md-1" *ngFor="let problem of problems;let i = index">{{ i + 1 }}</th>
                <th class="col-md-1">Time of Last Submission</th>
                <th class="col-md-1">Total Score</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let team of teams">
                    <td>{{ team.team }}</td>
                    <td *ngFor="let problem of problems;let j = index">{{ team.problems[j].score }}</td>
                    <td>{{ team.lastAcceptedSubmissionTimeString }}</td>
                    <td>{{ team.score }}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="col">
          <ul class="list-unstyled border border-3 p-2 rounded-3" id="legend">
            <li *ngFor="let problem of problems;let i = index">{{ i + 1 }}. {{ problem.title }}</li>
            <li class="mt-5 lead">Every solution that is incorrect removes 3 points from the point total.</li>
          </ul>
        </div>
      </div>
      <p class="lead mb-0" *ngIf="currentTime < startTime">The competition has not yet started.</p>
    </div>
  </div>
</div>
