<section class="hero is-light is-fullheight is-bold">
    <div class="container justify-content-center mt-5 mb-5">
      <div>
        <div class="alert alert-{{ feedbackType }} alert-dismissible " *ngIf="feedbackProblem === -1">
          {{ feedbackMessage }}
          <button type="button" class="btn-close" (click)="clearFeedback()"></button>
         </div>
        <div class="card">
          <div class="card-body">
            <h2 class="display-3">Announcements</h2>
            <p class="lead">Send an announcement to every team</p>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col">
                  <label for="message">Message</label>
                  <textarea class="form-control" id="message" rows="2" formControlName="message" placeholder="Enter message"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col">
                    <br>
                    <button type="button" class="btn btn-{{messageType}} dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      {{this.messageType !== MessageTypes.dark ? (this.messageType.charAt(0).toUpperCase() + this.messageType.slice(1)) : 'Message Type'}}
                    </button>
                      <ul id="messageTypeDropdown" class="dropdown-menu">
                        <li><a class="dropdown-item" (click)="setMessageType(MessageTypes.primary)">Primary</a></li>
                        <li><a class="dropdown-item" (click)="setMessageType(MessageTypes.secondary)">Secondary</a></li>
                        <li><a class="dropdown-item" (click)="setMessageType(MessageTypes.success)">Success</a></li>
                        <li><a class="dropdown-item" (click)="setMessageType(MessageTypes.info)">Info</a></li>
                        <li><a class="dropdown-item" (click)="setMessageType(MessageTypes.warning)">Warning</a></li>
                        <li><a class="dropdown-item" (click)="setMessageType(MessageTypes.danger)">Danger</a></li>
                      </ul>
                      <p id="invalidForm" *ngIf="this.invalidFormText !== ''" style="color : red">
                        {{this.invalidFormText}}
                      </p>
                </div>
                  <div class="col">
                    <br>
                    <button type="submit" class="btn btn-primary form-control"
                    [disabled] ="stopSendAnnouncements">Send</button>
                  </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <table class="table table-light table-striped caption-top">
              <caption>Past Announcements</caption>
              <thead>
                <tr>
                  <th scope="col">Message</th>
                  <th scope="col">Type</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
               <tr *ngFor="let message of messages; let i = index">
                  <td>{{ messages[messages.length - (i + 1)].message }}</td>
                  <td>{{ messages[messages.length - (i + 1)].messageType }}</td>
                  <td>{{ messages[messages.length - (i + 1)].time | date:'shortTime' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
