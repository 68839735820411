import { Component, Input } from '@angular/core';
import {ServerService} from '../services/server.service';

@Component({
  selector: 'problem-description',
  templateUrl: './problem-description.component.html',
  styleUrls: ['./problem-description.component.scss'],
})
export class ProblemDescriptionComponent {

  @Input()
  problemId: number;

  inputtedFiles: FileList;
  pathString: string;
  mdFile: File;
  presentString: string;
  loadingDescriptions = false;

  constructor(private server: ServerService){

  }

  ngOnInit(){
    // this.getDescriptions();
  }

  displayMarkdown(files: FileList): void{
    this.inputtedFiles = files;
    this.mdFile = undefined;
    for(let i = 0; i < this.inputtedFiles.length; i++){
      if(this.inputtedFiles[i].name.endsWith('.md') || this.inputtedFiles[i].name.endsWith('.markdown')){
        this.mdFile = this.inputtedFiles[i];
        break;
      }
    }
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      this.pathString = fileReader.result as string;
    }
    fileReader.readAsText(this.mdFile);
  }

  getDescriptions(): void{
    this.server.request("GET", this.server.routeProblemDescription + this.problemId).subscribe(response => {
      if(response.status === 200) {
        this.loadingDescriptions = false;
        this.pathString = response.markdown;
        const obj = {markdown: response.markdown, updatedTime: response.updatedTime}
        localStorage.setItem("problem-description-" + this.problemId, JSON.stringify(obj));
      }
    },
      error => {
        this.presentString = 'No Descriptions Available';
      });
  }

  checkIfCurrent(updatedTime) {
    this.server.request("POST", this.server.routeProblemDescriptionCurrent + this.problemId, {updatedTime}).subscribe(response => {
        if(response.status === 200 && !response.isCurrent) {
          this.pathString = "";
          localStorage.removeItem("problem-description-" + this.problemId);

          this.loadDescription();
        }
      },
      error => {
        // console.log("Hello")
      });
  }

  loadDescription() {
    if (!this.pathString) {
      const localCopy = localStorage.getItem("problem-description-" + this.problemId);
      if (localCopy) {
        const parsedLocalCopy = JSON.parse(localCopy);
        this.pathString = parsedLocalCopy.markdown;
        this.loadingDescriptions = false;

        // Async call will delete the local copy if it isn't current and reload the description.
        this.checkIfCurrent(parsedLocalCopy.updatedTime);
      } else if (!this.pathString && !this.presentString && !this.loadingDescriptions) {
        this.loadingDescriptions = true;
        this.getDescriptions();
      }
    }
  }
}
