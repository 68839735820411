import {DatePipe} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ServerService } from '../services/server.service';
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-register-admin',
  templateUrl: './contest-options.component.html',
  styleUrls: ['./contest-options.component.scss'],
})
export class RegisterAdminComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  startTime: Date;
  endTime: Date;
  currentTime: Date;
  scoreboardOption: string;
  hideScoreboardDate: Date;
  interval: any;

  status: string;
  hiddenStatus: string;

  constructor(private server: ServerService, private authService: AuthService) {
    this.currentTime = new Date();

    this.form = new UntypedFormGroup({
      startDateInput: new UntypedFormControl('', [Validators.required]),
      endDateInput: new UntypedFormControl('', [Validators.required]),
      hide: new UntypedFormControl('', [Validators.required]),
    });

    // this.form.disable();

    this.updateStatus();
  }

  ngOnInit(): void {
    this.clock();
    this.server.request('GET', this.server.routeTimeGet).subscribe((response) => {
      const datepipe: DatePipe = new DatePipe('en-US');
      this.startTime = new Date(response.start);
      this.endTime = new Date(response.end);
      this.hideScoreboardDate = new Date(response.hideTime);
      this.scoreboardOption = response.scoreboard;

      this.form.controls.startDateInput.setValue(
        datepipe.transform(this.startTime, 'yyyy-MM-ddTHH:mm')
      );
      this.form.controls.endDateInput.setValue(
        datepipe.transform(this.endTime, 'yyyy-MM-ddTHH:mm')
      );
      this.form.controls.hide.setValue(
        datepipe.transform(
          this.hideScoreboardDate.getTime(),
          'yyyy-MM-ddTHH:mm'
        )
      );
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  onSubmit(): void {
    const start = new Date(this.form.controls.startDateInput.value);
    const end = new Date(this.form.controls.endDateInput.value);
    const hideTime = new Date(this.form.controls.hide.value);

    this.server
      .request('PUT', this.server.routeTimeGet, {
        start,
        end,
        hideTime,
      })
      .subscribe(
        () => {
          this.startTime = start;
          this.endTime = end;
          this.hideScoreboardDate = hideTime;
        },
        () => console.log('failure')
      );

    this.updateStatus();
  }

  updateScoreboard(option: 'show' | 'hide' | 'timer'): void {
    this.server
      .request('PUT', this.server.routeTimeGet, {
        scoreboard: option,
      })
      .subscribe(
        () => {
          this.ngOnInit();
        },
        (e) => {
          console.log(e);
        }
      );

    this.updateStatus();
  }

  clock(): void {
    this.interval = setInterval(() => {
      this.updateStatus();
    }, 100);
  }

  updateStatus(): void {
    if (
      this.startTime === undefined ||
      this.endTime === undefined ||
      this.hideScoreboardDate === undefined
    ) {
      return;
    }
    this.currentTime = new Date();

    this.form.controls.startDateInput.setValidators([
      Validators.required,
      this.dateBefore(this.form.controls.endDateInput.value),
    ]);
    this.form.controls.endDateInput.setValidators([
      Validators.required,
      this.dateAfter(this.form.controls.startDateInput.value),
    ]);
    this.form.controls.hide.setValidators([
      Validators.required,
      this.dateBefore(this.form.controls.endDateInput.value),
      this.dateAfter(this.form.controls.startDateInput.value),
    ]);

    this.status =
      this.currentTime >= this.startTime
        ? 'The competition has ' +
          this.transform(this.endTime.getTime() - this.currentTime.getTime()) +
          ' remaining.'
        : 'Starting in ' +
          this.transform(this.startTime.getTime() - this.currentTime.getTime());

    if (this.scoreboardOption === 'timer') {
      this.hiddenStatus =
        this.currentTime <= this.hideScoreboardDate
          ? 'The scoreboard will remain visible for another ' +
            this.transform(
              this.hideScoreboardDate.getTime() - this.currentTime.getTime()
            ) +
            '.'
          : 'Scoreboard is now hidden.';
    } else if (this.scoreboardOption === 'hide') {
      this.hiddenStatus = '';
    } else {
      this.hiddenStatus = '';
    }

    if (this.currentTime >= this.endTime) {
      this.status = 'The competition is over.';
    }

    for (const i in this.form.controls) {
      this.form.controls[i].updateValueAndValidity();
    }
  }

  transform(time: number): string {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    const daysText = days > 0 ? days + ' day' + (days > 1 ? 's' : '') : '';
    const hoursText = hours > 0 ? hours + ' hour' + (hours > 1 ? 's' : '') : '';
    const minutesText =
      minutes > 0 ? minutes + ' minute' + (minutes > 1 ? 's' : '') : '';
    const secondsText =
      seconds > 0 ? seconds + ' second' + (seconds > 1 ? 's' : '') : '';

    return `${daysText} ${hoursText} ${minutesText} ${secondsText}`;
  }

  private dateAfter = (date?: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const provided = date === undefined ? new Date() : new Date(date);
      const after = provided <= new Date(control.value);
      return !after ? { dateAfter: { value: control.value } } : null;
    };
  };

  private dateBefore = (date?: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const provided = date === undefined ? new Date() : new Date(date);
      const after = provided >= new Date(control.value);
      return !after ? { dateAfter: { value: control.value } } : null;
    };
  };
}
