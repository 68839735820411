<div class="container">
  <div
    class="alert alert-{{ feedbackType }} alert-dismissible "
    *ngIf="feedbackProblem === -1"
  >
    {{ feedbackMessage }}
    <button type="button" class="btn-close" (click)="clearFeedback()"></button>
  </div>
  <div class="card">
    <div class="card-body">
      <h1 class="display-5 mb-0">Register Team</h1>
      <p class="lead">Please enter team details</p>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col">
            <label for="school">School Name</label>
            <input
              type="email"
              class="input form-control"
              name="school"
              id="school"
              formControlName="school"
              aria-describedby="schoolHelp"
              placeholder="Enter school name"
              required
            />
          </div>
          <div class="col">
            <label for="username">Username</label>
            <input
              type="username"
              class="input form-control"
              name="username"
              id="username"
              formControlName="username"
              aria-describedby="usernamehelp"
              placeholder="Enter username"
              required
            />
          </div>
          <div class="col-2">
            <br />
            <button type="submit" class="btn btn-primary form-control" [disabled]="stopTeamRegister">
              Register
            </button>
          </div>
        </div>
      </form>
      <br />
      <div class="row">
        <div class="col input-group">
          <input
            class="form-control"
            type="file"
            (change)="handleFileInput($event.target.files)"
            id="csv"
          />
          <button class="btn btn-success" type="button" (click)="onUpload()">
            <i class="fa fa-cloud-upload"></i> Upload
          </button>
        </div>
      </div>
      <hr class="mb-0">
      <table class="table table-light table-striped caption-top">
        <caption>
          List of Registered Teams
        </caption>
        <thead>
          <tr>
            <th width="2" scope="col">School</th>
            <th width="2" scope="col">Username</th>
            <th width="2" scope="col">Status</th>
            <th width="2" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let team of teams">
            <td>{{ team.school }}</td>
            <td>{{ team.username }}</td>
            <td>
              <i
                class="fas fa-user-{{ team.activated ? 'check' : 'times' }}"
              ></i>
              {{ team.activated ? "Activated" : "Not Activated" }}
            </td>
            <td *ngIf="team.activated && confirm != team.id">
              <button
                type="button"
                class="btn btn-sm btn-warning"
                (click)="confirm = team.id"
              >
                <i class="fa fa-trash"></i> Delete Password
              </button>
            </td>
            <td *ngIf="team.activated && confirm == team.id">
              <button
                type="button"
                class="btn btn-sm btn-danger"
                (click)="deletePassword(team.username)"
              >
                <i class="fa fa-trash"></i>&nbsp;<b>Confirm</b> Delete?
              </button>
            </td>
            <td *ngIf="!team.activated"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
