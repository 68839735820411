<div class="row justify-content-center">
  <div class="alert alert-{{ alertType }} alert-dismissible " *ngIf="alertFlag">
    {{ alertMessage }}
    <button type="button" class="btn-close" (click)="clearAlert()"></button>
  </div>
  <div class="d-inline-flex p-3">
    <div class="w-100 mr-3">
      <label for="teamFil">Filter by Team</label>
      <input #teamFilter id="teamFil" class="input form-control filter-group" type="text" placeholder="Filter Teams" (keyup)="applyFilter()"/>
    </div>
    <div class="mr-3 w-100">
      <label for="numberFil">Filter by Problem Number</label>
      <input #numberFilter id="numberFil" class="input form-control filter-group" type="text" placeholder="Filter Problems" (keyup)="applyFilter()"/>
    </div>
    <div *ngIf="getPreset()=='scored'" class="mr-3 w-100">
      <label for="numberFil">Filter by Judge</label>
      <input #judgeFilter id="judgeFil" class="input form-control filter-group" type="text" placeholder="Filter Judges" (keyup)="applyFilter()"/>
    </div>
  </div>
  <ul id="submissionsForm" class="list-group">
    <li *ngFor="let submission of filteredSubmissions; let i = index" class="list-group-item">
      <div class="row">
        <div class="col me-auto">
          <h6>Team: {{submission.school}} </h6>
          <h6>{{submission.problemTitle}} <span class="badge bg-secondary">{{getLangFromExt(submission.language)}}</span></h6>
        </div>
        <div class="col">
          <app-score-button [isCheckedOutElsewhere]="isCheckedOutElsewhere"
                            [isCheckedOutLocal]="isCheckedOutLocal"
                            (buttonClicked)="checkoutButtonClicked(submission)" [submission]="submission" [preset]="getPreset()"></app-score-button>
          <h6 *ngIf="getPreset()=='scored'">Scored at: {{getFormattedDate(submission.judgedAt)}}</h6>
          <h6 *ngIf="getPreset()=='scored'">Scored by: {{submission.judgeName}}</h6>
          <span *ngIf="getPreset()=='scored' && submission.verdict == 1" class="badge bg-secondary">Ignored</span>
          <span *ngIf="getPreset()=='scored' && submission.verdict == 2" class="badge bg-success">Passed</span>
          <span *ngIf="getPreset()=='scored' && submission.verdict == 3" class="badge bg-danger">Failed</span>
        </div>
      </div>
    </li>
  </ul>
  <app-feedback-modal [submission]="currentSubmission"
                      [preset]="getPreset()"
                      [isCheckedOutElseWhere]="isCheckedOutElsewhere"
                      (submissionReleased)="releaseSubmission($event.submission.id)"
                      (submissionIgnored)="submitComment($event.submission, -1)"
                      (submissionPassed)="submitComment($event.submission, 1)"
                      (submissionFailed)="submitComment($event.submission, 0)">

  </app-feedback-modal>
</div>
