<div>
  <button *ngIf="!runIsCheckedOutElsewhere(submission.id) && !runIsCheckedOutLocal(submission.id)" (click)="this.buttonClicked.emit()"
          type="button" class="btn btn-right btn-success" data-bs-toggle="modal"
          [attr.data-bs-target]="'#scoringModal'+preset">
    <i class="fa fa-edit"></i>
    <span *ngIf="preset !== 'scored'">
      Score
    </span>
    <span *ngIf="preset === 'scored'">
      Re-Score
    </span>
  </button>
  <button *ngIf="runIsCheckedOutLocal(submission.id)" type="button"
          (click)="this.buttonClicked.emit()"
          class="btn btn-right btn-warning col-md-4" data-bs-toggle="modal" [attr.data-bs-target]="'#scoringModal'+preset">
    <i class="fa fa-edit"></i>
    Continue
  </button>
  <button *ngIf="runIsCheckedOutElsewhere(submission.id) && !runIsCheckedOutLocal(submission.id)" type="button"
          (click)="this.buttonClicked.emit()"
          class="btn btn-right btn-secondary col-md-4" data-bs-toggle="modal" [attr.data-bs-target]="'#scoringModal'+preset">
    Checked Out
  </button>
</div>
