import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AnnouncementService} from "../services/announcement.service";
import {Subscription} from "rxjs";
import {Announcement, BootstrapTypes} from "../models/responseObjects";
import {ServerService} from "../services/server.service";
import {skip, take} from "rxjs/operators";

const DEFAULT_COLOR = BootstrapTypes.dark;

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  feedbackProblem: number;
  messages: Announcement[] = [];
  messageInput: string;
  messageType: BootstrapTypes;

  currentTime: Date;
  announcementSubscription: Subscription;
  invalidFormText: string;

  stopSendAnnouncements = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private announcementService: AnnouncementService,
    private server: ServerService,
  ) {
    this.form = new UntypedFormGroup({
      message: new UntypedFormControl(),
      messageType: new UntypedFormControl()
    });
   }

  ngOnInit(): void {
    this.messageType = DEFAULT_COLOR;
    this.invalidFormText = '';
    this.startObservingAnnouncements();
  }

  startObservingAnnouncements() {
    const subscribeCallback = (response) => {
      this.messages = response;
    }
    this.announcementSubscription = this.announcementService.pollObservable
      .subscribe(response => subscribeCallback(response));
  }

  onSubmit(): void {
    this.invalidFormText = '';
    this.currentTime = new Date();
    this.messageInput = this.form.get("message").value;

    if (!this.messageInput){
      this.invalidFormText = 'No Message Inputted.';
    } else if (this.messageType === DEFAULT_COLOR) {
      this.invalidFormText = 'No Message Type Selected.';
    } else {
      this.stopSendAnnouncements = true;
      this.server.request( 'POST', this.server.routeMessagePost, {
        message: this.messageInput,
        messageType: this.messageType,
        time: this.currentTime,
      }).subscribe(() => {
        this.announcementService.pollObservable.pipe(take(2), skip(1)).subscribe(() => {

        });
      }, (err) => {
        console.log('Error on posting announcement: ', err);
      }).add(() => {

        this.form.controls["message"].setValue(undefined);
        this.messageType = DEFAULT_COLOR;
        this.stopSendAnnouncements = false
      });
    }
  }

  async setMessageType(selectedType: BootstrapTypes): Promise<void> {
    this.messageType = selectedType;
  }

  ngOnDestroy(): void {
    this.announcementSubscription.unsubscribe();
  }

  public get MessageTypes(): typeof BootstrapTypes {
    return BootstrapTypes;
  }

}
