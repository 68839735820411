import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import { AuthService } from '../services/auth.service';
import {ServerService} from '../services/server.service';

@Component({
  selector: 'app-register-judge',
  templateUrl: './register-judge.component.html',
  styleUrls: ['./register-judge.component.scss']
})

export class RegisterJudgeComponent implements OnInit {
  form: UntypedFormGroup;

  feedbackProblem: number;
  feedbackMessage: string;
  feedbackType: string;

  judges: object[];

  constructor(
    private server: ServerService,
    private authService: AuthService
  ) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(),
      username: new UntypedFormControl()
    });

    this.form.get('name').setValidators(Validators.required);
    this.form.get('username').setValidators(Validators.required);
  }

  async ngOnInit(): Promise<void> {
    this.judges = await this.getJudges();
  }

  async onSubmit(): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.form.valid) {
        this.feedbackProblem = -1;
        this.feedbackMessage = 'Form not valid. Please check that fields are correctly filled in';
        this.feedbackType = 'danger';
        return;
      }
      const request = this.server.request('POST', this.server.routeRegisterJudgePost, {
        Name: this.form.get('name').value,
        Username: this.form.get('username').value
      }).subscribe(async (e:any) => {
        // this.feedbackProblem = -1;
        // this.feedbackMessage = `Successfully Registered Judge: ${(document.getElementById("name") as HTMLInputElement).value}`;
        // this.feedbackType = 'success';
        this.form.get('name').setValue(undefined);
        this.form.get('username').setValue(undefined);
        document.getElementById('name').focus();
        this.ngOnInit();
        resolve();
      }, (error) => {
        this.feedbackProblem = -1;
        this.feedbackMessage = `Error: ${error.message}; Back-end may have crashed`;
        this.feedbackType = 'danger';
      });
    });
  }

  async deletePassword(username: string): Promise<void> {
    const result = await this.authService.setPassword(username, null);
    if (result.success) {
      this.ngOnInit();
      // this.feedbackProblem = -1;
      // this.feedbackMessage = 'Successfully deleted password.';
      // this.feedbackType = 'success';
    } else {
      this.feedbackProblem = -1;
      this.feedbackMessage = 'Failed to delete password. (' + result.message + ')';
      this.feedbackType = 'danger';
    }
  }

  async toggleAdmin(judge: any) {
    const result = await this.authService.setUserType(judge.id, judge.type === 'admin' ? 'judge' : 'admin');
    if (result.success) {
      this.feedbackProblem = -1;
      this.feedbackMessage = `Successfully made ${judge.name} ${judge.type === 'admin' ? 'a judge' : 'an admin'}.`;
      this.feedbackType = 'success';
      this.ngOnInit();
    } else {
      this.feedbackProblem = -1;
      this.feedbackMessage = 'Failed to modify status (' + result.message + ')';
      this.feedbackType = 'danger';
    }
  }

  private async getJudges(): Promise<any[]> {
    return this.server.request('GET', this.server.routeJudgeGetAll).toPromise()
      .catch(error => {
        this.feedbackProblem = -1;
        this.feedbackMessage = `Error: ${error.error}`;
        this.feedbackType = 'danger';
      });
  }
  clearFeedback(): void {
    this.feedbackProblem = undefined;
    this.feedbackMessage = undefined;
    this.feedbackType = undefined;
  }
}
