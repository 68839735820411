import { ServerService } from '../services/server.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Router} from '@angular/router';

import { Socket, io } from "socket.io-client";
import { baseUrl } from '../services/server.service';
import { WebsocketIO } from '../services/websockets-io';


@Component({
  selector: 'app-team-announcement',
  templateUrl: './team-announcement.component.html',
  styleUrls: ['./team-announcement.component.scss']
})
export class TeamAnnouncementComponent implements OnInit, OnDestroy {

  announcements: Object[];
  interval: any;

  socket: WebsocketIO = undefined;

  constructor(
    private server: ServerService,
    private router: Router,
    private changeDetection: ChangeDetectorRef
  ) { }


  async ngOnInit(): Promise<void> {
    if(this.socket === undefined || this.socket.isClosed()){
      this.socket = new WebsocketIO("annoucement", async () => {
        this.announcements = await this.getMessages();
      });
    }
    this.announcements = await this.getMessages();
    this.startInterval();
  }



  ngOnDestroy(): void {
    this.socket.closeSocket();
  }

  async startInterval() {
    this.interval = setInterval( async() => {
      this.announcements = await this.getMessages();
    }, 2000)
  }

  private async getMessages(): Promise<any[]> {
    return await new Promise<any[]>(resolve => {
      this.server.request('GET', this.server.routeMessageGetAll).subscribe(response => {
        resolve(response);
      },
      (error) => {
        console.log(error)
      });
    });
  }

}
