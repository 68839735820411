import {Component, OnInit, ViewChild, ElementRef, QueryList, OnDestroy, Output, EventEmitter} from '@angular/core';
import { ServerService } from '../services/server.service';
import { TempFileStoreModule } from '../services/temp-file-store.module';
import { Socket, io } from "socket.io-client";
import { WebsocketIO } from '../services/websockets-io';

const mdr = require('markdown-it')();

@Component({
  selector: 'app-problems-accordion',
  templateUrl: './problems-accordion.component.html',
  styleUrls: ['./problems-accordion.component.scss']
})

export class ProblemsAccordionComponent implements OnInit, OnDestroy {
  @Output() giveFeedbackEmitter = new EventEmitter<any>();
  @Output() clearFeedbackEmitter = new EventEmitter<any>();

  socket: WebsocketIO = undefined;

  selectedFile: File;
  fileText: string;
  problems: any[];

  expandedProblem: number;

  feedbackProblem: number;
  feedbackMessage: string;
  feedbackType: string;

  problemsURL: string = this.server.routeProblemGetAllReal;

  intervalId: any;

  // If true, disables uploading submissions to ANY problem
  stopAllUpload = false;

  @ViewChild('problemHeaders') problemHeaders: QueryList<ElementRef>;

  constructor(protected server: ServerService) {
  }

  ngOnInit(): void {
    if(this.socket === undefined || this.socket.isClosed()){
      this.socket = new WebsocketIO("graded", () => {
        this.reloadComments().then();
      });
    }
    this.reloadProblems().then();
  }

  async reloadProblems(): Promise<void> {
    await this.refreshPage();
    this.problems = (await this.getProblems()).sort((p1, p2) => p1.order - p2.order);
    for (const problem of this.problems) {
      problem.submissions = await this.getSubmissions(problem.id);
    }
    await this.reloadComments();
  }

  private async reloadComments(): Promise<void> {
    await this.updateScores();
    if (!this.intervalId) {
      await this.checkForUpdates(); // Fetches Comments from db
    }
  }

  private async getProblems(): Promise<any[]> {
    return await new Promise<any[]>(resolve => {
        this.server.request('GET', this.problemsURL).subscribe(response => {
            resolve(response);
          },
          (_) => {
            this.giveFeedback(-2, 'An error occurred loading the problems.', 'danger');
          });
      });
  }

  private async getSubmissions(problemId: number): Promise<any[]> {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData === null) {
      return;
    }
    return await new Promise<any[]>(resolve => {
      this.server.request('GET', `/api/submission/team/${userData.id}/problem/${problemId}?withInfo=true`).subscribe(response => {
        const submissions = [];
        for (const sub of response.submissions) {
          sub.comments = [];
          submissions.push(sub);
        }
        resolve(submissions);
      });
    });
  }

  private async getSubmissionIds(problemId: number): Promise<any[]> {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData === null) {
      return;
    }
    return await new Promise<any[]>(resolve => {
      this.server.request('GET', `/api/submission/team/${userData.id}/problem/${problemId}?withInfo=false`).subscribe(response => {
        resolve(response.submissions);
      });
    });
  }

  private async getSubmissionInfo(submissionId: number): Promise<any> {
    return await new Promise<any>(resolve => {
      this.server.request('GET', this.server.routeSubmissionGetSpecific + submissionId + '?withCode=false').subscribe(response => {
        response.comments = [];
        resolve(response);
      });
    });
  }

  private async getSubmissionCode(submissionId: number): Promise<any> {
    return this.server.request('GET', this.server.routeSubmissionGetSpecific + submissionId + '?withCode=true')
      .toPromise()
      .then(response => {
        return response;
      });
  }

  handleFileInput(files: FileList): void {
    this.selectedFile = files[0];
  }

  async onUpload(problemId: number): Promise<void> {
    return new Promise(async () => {
      if (this.selectedFile == null) {
        this.giveFeedback(problemId, 'You must select a file to submit.', 'danger');
        return;
      } else if (this.selectedFile.size > 1000000) {
        this.giveFeedback(problemId, 'The file you have submitted is too large.', 'danger');
        return;
      }
      this.fileText = await this.selectedFile.text();
      const lang = this.selectedFile.name.split('.').reverse()[0];

      TempFileStoreModule.appendSubmission(problemId, this.selectedFile);
      this.sendSubmission(problemId, lang);
    });
  }

  sendSubmission(problemId: number, lang: string): void {
    this.stopAllUpload = true;
    this.server.request('POST', this.server.routeSubmissionPost, {
      code: this.fileText,
      lang, problemId, name: this.selectedFile.name
    }).subscribe(async (_: any) => {
      //TODO: Remove this before merge
      // await new Promise((r) => setTimeout(r, 1000));

      await this.updateSubmissions();
      await this.updateScores();
      this.problems.find(p => p.id === problemId).curentVerdict = undefined;
      this.giveFeedback(-1, `Successfully submitted solution for "${this.findProblemFromID(problemId).title}."`, 'success');
    }, (error) => {
      this.giveFeedback(problemId, error.error, 'danger');
    }, () => {
      this.selectedFile = null;
      this.stopAllUpload = false;
      (document.getElementById(`submission${problemId}`) as HTMLInputElement).value = '';
    });
  }

  /**
   * Renders feedback on the page
   * @param problemId -2 = alert box in card above problems; -1 = alert box above card; 0+ = form field for specified problem id
   * @param message message to display
   * @param type feedback type; see https://getbootstrap.com/docs/5.1/components/alerts/#examples (success, danger, warning, info, etc.)
   */
  giveFeedback(problemId: number, message: string, type: string): void {
    this.giveFeedbackEmitter.emit({problemId, message, type});
  }

  clearFeedback(): void {
    this.clearFeedbackEmitter.emit();
  }

  async download(submission: any): Promise<void> {
    const submissionWithCode = await this.getSubmissionCode(submission.id);
    const blob = new Blob([submissionWithCode.code]);
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = submission.fileName;
    anchor.href = url;
    anchor.click();
  }

  async checkForUpdates(): Promise<any[]> {
    // Gets comments and submissions from database every 3 seconds
    this.intervalId = setInterval(async () => {
      await this.updateSubmissions();
      await this.updateScores();
    }, 3000);
    return;
  }

  private async updateScores(): Promise<void> {
    const tempComments = await this.getCommentsByTeam();
    for (const comment of tempComments) {
      const commentProblem = this.problems.find(p => p.id === comment.problemId);
      if (commentProblem) {
        const commentSubmission = commentProblem.submissions.find(s => s.id === comment.submissionId);
        if (!commentSubmission.comments.some(ci => ci.id === comment.id)) {
          commentSubmission.comments.push(comment);
          const submissionFeedbackButton = document.getElementById('comments-' + comment.submissionId) as HTMLButtonElement;
          submissionFeedbackButton.disabled = false;
          commentSubmission.currentVerdict = comment.verdict;
        }
      }
    }

    for (const problem of this.problems) {
      let verdict;
      for (const sub of problem.submissions) {
        if (sub.currentVerdict === 'accepted') {
          verdict = 'accepted';
        }
      }
      if (verdict === undefined && problem.submissions.length > 0) {
        if (problem.submissions.at(-1).currentVerdict === 'wrong answer') {
          verdict = 'wrong answer';
        } else if (problem.submissions.at(-1).currentVerdict === 'no verdict') {
          verdict = 'no verdict';
        }
      }
      problem.currentVerdict = verdict;
    }
  }

  private async updateSubmissions(): Promise<void> {
    for (const problem of this.problems) {
      const submissionIds = await this.getSubmissionIds(problem.id);
      for (const submissionId of submissionIds) {
        if (!problem.submissions.some(si => si.id === submissionId.submissionId)) {
          const submission = await this.getSubmissionInfo(submissionId.submissionId);
          if (!problem.submissions.some(si => si.id === submissionId.submissionId)) {
            problem.submissions.push(submission);
            problem.latestSubmission = submission;
            this.giveFeedback(-1, `A new solution was submitted for "${problem.title}".`, 'success');
          }
        }
      }

      // Remove any submissions that no longer exist for this problem.
      problem.submissions = problem.submissions.filter(s => submissionIds.some(si => si.submissionId === s.id))
    }
  }

  async showComments(submission: any): Promise<any> {
    let latestComment = null;

    // Find the latest comment for when a Judge re-scores the same submission
    for (const comment of submission.comments) {
      if (latestComment == null) {
        latestComment = comment;
      } else if (latestComment.id < comment.id) {
        latestComment = comment;
      }
    }

    // Add judges comments to modal body
    const textField = document.getElementById('modalComment-' + submission.id);
    textField.innerHTML = mdr.render(latestComment.content);

    // Add Submission filename and judges verdict to modal header
    const modalTitle = document.getElementById('modalTitle-' + submission.id);
    modalTitle.innerHTML = 'Submission: ' + submission.fileName + '<br> Response: ' + latestComment.verdict;
  }

  private async getCommentsByTeam(): Promise<any[]> {
    return await new Promise<any[]>(resolve => {
      const userData = JSON.parse(localStorage.getItem('user'));
      this.server.request('GET', `/api/comment/team/${userData.id}`).subscribe(response => {
          resolve(response.comments);
        },
        (_) => {
          this.giveFeedback(-2, 'An error occurred loading the comments.', 'danger');
        });
    });
  }

  private findProblemFromID(id: number): any {
    return this.problems.find(p => p.id === id);
  }

  refreshPage() {
    this.problems = [];
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  // Stops interval when user leaves the problem page.
  async ngOnDestroy(): Promise<any> {
    this.socket.closeSocket(); //Disconnect socket when not in use.
    // clearInterval(this.intervalId);
  }
}
