<div class="container">
  <div class="card">
    <div class="card-body">
      <h1 class="display-5">Manage Problems</h1>
      <div
        class="alert alert-danger mt-3"
        *ngIf="this.error.show && this.error.form == -1"
      >
        {{ this.error.message }}
      </div>
      <div *ngIf="!this.problemsLocked">
        <form [formGroup]="form" (submit)="onSubmit()">
          <div class="row">
            <div class="col-12 col-md-8 " [ngClass]="practiceMarked ? 'col-md-10' : 'col-md-8'">
              <label for="title"
                >Problem Title
                <span
                  class="text-danger"
                  *ngIf="this.error.show && this.error.form === 1"
                  >{{ this.error.message }}</span
                ></label
              >
              <input
                type="text"
                class="input form-control {{
                  this.error.show && this.error.form === 1 ? 'is-invalid' : null
                }}"
                name="title"
                id="title"
                formControlName="title"
                required
              />

              <div class="form-check mt-2">
                <input type="checkbox" class="form-check-input" id="practiceControlInline" (change)="practiceCheckboxChecked($event)" name="markPractice" formControlName="markPractice" >
                <label class="custom-control-label" for="practiceControlInline">Mark as Practice</label>
              </div>
            </div>
            <div *ngIf="!practiceMarked" class="col-12 col-md-2">
              <label for="points">Points</label>
              <input
                type="number"
                class="input form-control {{
                  this.error.show && this.error.form === 1 ? 'is-invalid' : null
                }}"
                name="points"
                id="points"
                formControlName="points"
                required
              />
            </div>
            <div class="col-12 col-md-2">
              <br />
              <button type="submit" class="btn btn-primary form-control">
                Add
              </button>
            </div>
            <label for="md">Markdown Description</label>
            <div class="col input-group">
              <input
                #descriptionUpload
                class="form-control"
                type="file"
                id="md"
                multiple
              />
            </div>
          </div>
        </form>
        <hr />
        <form [formGroup]="form1" (submit)="onSubmitCSV()">
          <div class="row">
            <div class="col-12 col-md-10">
              <label for="csv"
                >CSV
                <span
                  class="text-danger"
                  *ngIf="this.error.show && this.error.form === 2"
                  >{{ this.error.message }}</span
                ></label
              >
              <input
                type="file"
                class="form-control {{
                  this.error.show &&
                  (this.error.form === 2 || this.error.form === 3)
                    ? 'is-invalid'
                    : null
                }}"
                name="csv"
                id="csv"
                formControlName="csv"
                (change)="handleFileInput($event.target.files)"
                required
              />
            </div>
            <div class="col-12 col-md-2">
              <br />
              <button type="submit" class="btn btn-primary form-control">
                Add
              </button>
            </div>
          </div>
          <div
            class="alert alert-danger p-2 mt-3"
            *ngIf="this.error.show && this.error.form === 3"
          >
            {{ this.error.message }}
          </div>
        </form>
      </div>
      <div class="alert alert-info mt-3" *ngIf="this.problemsLocked">
        <i class="fa fa-info-circle"></i> The contest has started and problems
        cannot be created, deleted, or re-ordered.
      </div>
      <div id="practiceProblemsList" *ngIf="practiceProblems.length > 0">
        <hr />
        <table class="table table-light table-striped caption-top mb-0">
          <caption>
            List of Practice Problems
          </caption>
          <thead>
          <tr>
            <th scope="col" width="25">Order</th>
            <th scope="col" width="500">Name</th>
            <th scope="col" width="250">Action</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let problem of practiceProblems; let i = index">
            <tr>
              <td>{{ i + 1 }}</td>
              <td *ngIf="editId !== problem.id">{{ problem.title }}</td>
              <td *ngIf="editId === problem.id">
                <input
                  id="newtitle"
                  type="text"
                  class="form-control"
                  value="{{ problem.title }}"
                />
              </td>
              <td>
                <button class="btn btn-sm btn-primary" (click)="edit(problem.id)">
                  <i
                    [ngClass]="{
                    fa: true,
                    'fa-pencil': editId !== problem.id,
                    'fa-save': editId === problem.id
                  }"
                  ></i></button
                >&nbsp;
                <button
                  [ngClass]="{
                  'btn btn-sm': true,
                  'btn-primary': i !== 0,
                  'btn-secondary': i === 0
                }"
                  [disabled]="i === 0"
                  (click)="moveUp(i, practiceProblems)"
                  *ngIf="!this.problemsLocked"
                >
                  <i class="fa fa-angle-up"></i> Move</button
                >&nbsp;
                <button
                  [ngClass]="{
                  'btn btn-sm': true,
                  'btn-primary': i !== practiceProblems.length - 1,
                  'btn-secondary': i === practiceProblems.length - 1
                }"
                  [disabled]="i === practiceProblems.length - 1"
                  (click)="moveDown(i, practiceProblems)"
                  *ngIf="!this.problemsLocked"
                >
                  <i class="fa fa-angle-down"></i> Move</button
                >&nbsp;
                <button
                  class="btn btn-sm btn-warning"
                  (click)="this.confirmDelete = problem.id"
                  *ngIf="
                  this.confirmDelete !== problem.id && !this.problemsLocked
                "
                >
                  <i class="fa fa-trash"></i> Delete
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  (click)="delete(problem.id)"
                  *ngIf="
                  this.confirmDelete === problem.id && !this.problemsLocked
                "
                >
                  <i class="fa fa-trash"></i> Delete?
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="4" *ngIf="editId === problem.id">
                <input
                  class="form-control"
                  type="file"
                  id="newDescription"
                  multiple
                />
              </td>
              <td colspan="4" *ngIf="editId !== problem.id">
                <div *ngIf="problem.fileLocation !== null">
                  <problem-description [problemId]="problem.id"></problem-description>
                </div>
                <div *ngIf="problem.fileLocation === null">
                  <div class="accordion">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" aria-expanded="false" [ngClass]="{'empty-problem-description': problem.fileLocation === null}" disabled>
                          No Problem Description
                        </button>
                      </h2>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div id="problemsList" *ngIf="problems.length > 0">
        <hr />
        <table class="table table-light table-striped caption-top mb-0">
          <caption>
            List of Problems
          </caption>
          <thead>
          <tr>
            <th scope="col" width="25">Order</th>
            <th scope="col" width="500">Name</th>
            <th scope="col" width="25">Points</th>
            <th scope="col" width="250">Action</th>
          </tr>
          </thead>
          <tbody>
        <ng-container *ngFor="let problem of problems; let i = index">
        <tr>
          <td>{{ i + 1 }}</td>
          <td *ngIf="editId !== problem.id">{{ problem.title }}</td>
          <td *ngIf="editId !== problem.id">{{ problem.points }}</td>
          <td *ngIf="editId === problem.id">
            <input
              id="newtitle"
              type="text"
              class="form-control"
              value="{{ problem.title }}"
            />
          </td>
          <td *ngIf="editId === problem.id && !this.problemsLocked">
            <input
              id="newpoints"
              type="number"
              class="form-control"
              value="{{ problem.points }}"
            />
          </td>
          <td *ngIf="editId === problem.id && this.problemsLocked">
            {{ problem.points }}
          </td>
          <td>
            <button class="btn btn-sm btn-primary" (click)="edit(problem.id)">
              <i
                [ngClass]="{
                    fa: true,
                    'fa-pencil': editId !== problem.id,
                    'fa-save': editId === problem.id
                  }"
              ></i></button
            >&nbsp;
            <button
              [ngClass]="{
                  'btn btn-sm': true,
                  'btn-primary': i !== 0,
                  'btn-secondary': i === 0
                }"
              [disabled]="i === 0"
              (click)="moveUp(i, problems)"
              *ngIf="!this.problemsLocked"
            >
              <i class="fa fa-angle-up"></i> Move</button
            >&nbsp;
            <button
              [ngClass]="{
                  'btn btn-sm': true,
                  'btn-primary': i !== problems.length - 1,
                  'btn-secondary': i === problems.length - 1
                }"
              [disabled]="i === problems.length - 1"
              (click)="moveDown(i, problems)"
              *ngIf="!this.problemsLocked"
            >
              <i class="fa fa-angle-down"></i> Move</button
            >&nbsp;
            <button
              class="btn btn-sm btn-warning"
              (click)="this.confirmDelete = problem.id"
              *ngIf="
                  this.confirmDelete !== problem.id && !this.problemsLocked
                "
            >
              <i class="fa fa-trash"></i> Delete
            </button>
            <button
              class="btn btn-sm btn-danger"
              (click)="delete(problem.id)"
              *ngIf="
                  this.confirmDelete === problem.id && !this.problemsLocked
                "
            >
              <i class="fa fa-trash"></i> Delete?
            </button>
          </td>
        </tr>
          <tr>
            <td colspan="4" *ngIf="editId === problem.id">
              <input
                class="form-control"
                type="file"
                id="newDescription"
                multiple
              />
            </td>
            <td colspan="4" *ngIf="editId !== problem.id">
              <div *ngIf="problem.fileLocation !== null">
                <problem-description [problemId]="problem.id"></problem-description>
              </div>
              <div *ngIf="problem.fileLocation === null">
                <div class="accordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" aria-expanded="false" [ngClass]="{'empty-problem-description': problem.fileLocation === null}" disabled>
                        No Problem Description
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </ng-container>
        </tbody>
        </table>
      </div>
      <hr *ngIf="practiceProblems.length === 0 && problems.length === 0" />
      <div id="noProblemsPlaceholder" *ngIf="practiceProblems.length === 0 && problems.length === 0">
        <p>No problems yet</p>
      </div>
    </div>
  </div>
</div>
