import { Component } from '@angular/core';

@Component({
  selector: 'app-about-page',
  templateUrl: './aboutpage.component.html',
  styleUrls: ['./aboutpage.component.scss']
})
export class AboutPageComponent {

  constructor() {
  }

}
