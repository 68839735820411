import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';

@Component({//displays the teams score and comments
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  Username: string;
  Team_name: string;
  showComment: boolean;

  constructor(private server: ServerService) {
    this.showComment = false;
   }

  button1(): void{
    // console.log(this.showComment);
    if(this.showComment){
      this.showComment = false;
    } else{
      this.showComment = true;
    }
  }
  getshowComment(): boolean{
    // console.log("inside getshowcomment");
    return this.showComment;
  }
  ngOnInit(): void {
    this.server.request('GET', this.server.routeProfileGet).subscribe((user: any) => {
      if (user) {
        this.Username = user.Username;
        this.Team_name = user.Team_name;
      }
    });
  }
}
