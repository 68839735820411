import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subscription, throwError} from 'rxjs';
import {ServerService} from './server.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';


/**
 * Authorizes users to see if they're a team, judge, or the admin.
 * This file is what allows users to stay logged in once a user closes the browser.
 *
 */
@Injectable()
export class AuthService {
  private helper: JwtHelperService;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private teamLoggedIn = new BehaviorSubject<boolean>(false);
  private judgeLoggedIn = new BehaviorSubject<boolean>(false);
  private AdminLoggedIn = new BehaviorSubject<boolean>(false);

  private token: string;

  get isLoggedIn(): Observable<boolean> {

    return this.loggedIn.asObservable();
  }

  get isTeamLoggedIn(): Observable<boolean> {
    return this.teamLoggedIn.asObservable();
  }

  get isJudgeLoggedIn(): Observable<boolean> {
    return this.judgeLoggedIn.asObservable();
  }

  get isAdminLoggedIn(): Observable<boolean> {
    return this.AdminLoggedIn.asObservable();
  }

  constructor(private router: Router, private server: ServerService) {
    this.helper = new JwtHelperService();
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.token = user.token;
      if (this.helper.isTokenExpired(this.token)) {
        this.logout();
        return;
      }
      this.server.setLoggedIn(true, this.token);
      if (user.type === 'team') {
        this.teamLoggedIn.next(true);
      }
      if (user.type === 'judge') {
        this.judgeLoggedIn.next(true);
      }
      if (user.type === 'admin') {
        this.AdminLoggedIn.next(true);
      }
      this.loggedIn.next(true);
    }
  }

  login(user, callback): Subscription {
    if (user.Username !== '' && user.Password !== '') {
      // console.log('login auth service');
      return this.server.request('POST', '/api/login', {
        Username: user.Username,
        Password: user.Password
      }).subscribe((response: any) => {
        // console.log(response.role);
        if (response.role === 'team' && response.token !== undefined) { // checks if the user is a team
          this.token = response.token;
          this.server.setLoggedIn(true, this.token);
          this.loggedIn.next(true);
          this.teamLoggedIn.next(true);
          const userData = {
            token: this.token,
            type: 'team',
            id: response.userId,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          this.router.navigate(['/problems']);

        } else if (response.role === 'judge' && response.token !== undefined) {// checks if the user is a judge
          this.token = response.token;
          this.server.setLoggedIn(true, this.token);
          this.loggedIn.next(true);
          this.judgeLoggedIn.next(true);
          const userData = {
            token: this.token,
            type: 'judge',
            id: response.userId,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          this.router.navigate(['/judge']);

        } else if (response.role === 'admin' && response.token !== undefined) { // checks if the user is the admin
          this.token = response.token;
          this.server.setLoggedIn(true, this.token);
          this.loggedIn.next(true);
          this.AdminLoggedIn.next(true);
          const userData = {
            token: this.token,
            type: 'admin',
            id: response.userId,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          this.router.navigate(['/admin-home']);
        } else {
          console.log('No auth chosen');
        }
      }, (error) => {
        callback(error);
      });
    }
  }

  /**
   * Used to set the password of a new account.
   * @param username username to set the password for
   * @param password password to set
   * @returns status
   */
  async setPassword(username: string, password: string): Promise<{success: boolean, message: string}> {
    return new Promise((resolve) => {
      const header = {Authorization: `Bearer ${this.token}`};
      this.server.request('POST', '/api/set-password', {
        Username: username,
        Password: password,
        headers: header
      }).subscribe(
        (response) => {
          // success: password set
          resolve({success: true, message: response.message + ' You will be logged in shortly.'});
        }, (error) => {
          // failure: password already set or user does not exist
          resolve({success: false, message: error.error.message});
        }
      );
    });
  }

  /**
   * Used to set the user type of an account.
   * @param id id of the user
   * @param type the type of user to set
   * @returns status
   */
  async setUserType(id: number, type: 'admin'|'judge'): Promise<{success: boolean, message: string}> {
    return new Promise(resolve => {
      const header = {Authorization: `Bearer ${this.token}`};
      this.server.request('PUT', '/api/judge', {
        id, type
      }).subscribe(
        response => resolve({ success: true, message: response.message }),
        error => resolve({success: false, message: error.error.message})
      );
    });
  }

  logout(): void {
    // logs the user out. Make sure to clear everything and set all variables to false.
    // Otherwise the authentication will not work correctly.
    this.server.setLoggedIn(false);
    delete this.token;

    this.loggedIn.next(false);
    this.AdminLoggedIn.next(false);
    this.judgeLoggedIn.next(false);
    this.teamLoggedIn.next(false);

    localStorage.clear();
    this.router.navigate(['/']);
  }
}


@Injectable()
export class LogoutInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.auth.logout();
        }
        return throwError(error);
      })
    );
  }
}

