<div class="accordion">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#code-preview-accordion-' + submission.id" aria-expanded="false">
        Code Preview
      </button>
    </h2>
    <div [id]="'code-preview-accordion-' + submission.id" class="accordion-collapse collapse overflow-scroll code-view-height" aria-labelledby="headingOne">
      <div class="accordion-body">
        <div class="accordion accordion-flush">
          <div class="accordion-item">
            <pre class="inherit-scrollbar"><code #codeView></code></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
