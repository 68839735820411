/*
https://github.com/svierk/angular-bootstrap-toast-service
This and both the toasts used this project's code heavily
*/
import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Announcement, BootstrapTypes} from "../models/responseObjects";
import {AnnouncementService} from "./announcement.service";
import {AuthService} from "./auth.service";

export interface ToastEvent {
  type: BootstrapTypes;
  title: string;
  message: string;
}

/**
 * Service used to send toast notifications.
 * Only sends toasts when there is a user logged in.
 *
 * Use showToast to queue a toast to be displayed to the screen.
 *
 * Internally initializes announcement notifications.
 */

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private notifiedAnnouncements: Announcement[] = [];

  toastEvents: Observable<ToastEvent>;
  private toastEventsSubject = new Subject<ToastEvent>();
  private announcementSubscription = new Subscription();

  constructor(private announcementService: AnnouncementService, private authService: AuthService) {
    this.toastEvents = this.toastEventsSubject.asObservable();
    this.notifiedAnnouncements = this.announcementService.receivedAnnouncements;
    this.authService.isLoggedIn.subscribe((logged) => {
      //When we log in, initialize the toasts - when we log out, stop looking for them.
      if (logged)
        this.initAnnouncementToasts();
      else {
        this.announcementSubscription.unsubscribe()
      }
    });
  }

  /**
   * Initializes the toasts for the announcements
   */
  initAnnouncementToasts() {
    const subscribeCallback = (response: Announcement[]) => {
      const filteredAnnouncements = response.splice(this.notifiedAnnouncements.length);
      for (const announcement of filteredAnnouncements) {
        this.showToast(`Announcement: ${new Date(announcement.time).toLocaleTimeString()}`, announcement.message, announcement.messageType)
      }
      this.notifiedAnnouncements.push(...filteredAnnouncements);
    }

    this.announcementSubscription = this.announcementService.pollObservable
      .subscribe(response => subscribeCallback(response))
  }

  /**
   * Shows a toast to the screen with the provided title and message body, and
   * using the color provided in type
   * @param title title of the toast
   * @param message body of the toast
   * @param type color of the toast
   */
  public showToast(title: string, message: string, type: BootstrapTypes) {
    this.toastEventsSubject.next({
      message,
      title,
      type,
    });
  }

  /**
   * Resets the toast notifications
   * ONLY USE FOR DEBUGGING - THIS SHOULD NEVER APPEAR OTHERWISE
   */
  public _debugResetToasts() {
    this.notifiedAnnouncements = [];
  }

}
